import React from 'react';
import { Footer } from '../../components/Common';

import { FdPostContainer } from '../../components/FeedDetail';
import { FlHeader } from '../../components/FeedList';
import { ErrorPage } from '..';

const FeedDetail = ({ match }) => {
  const { category, postPk } = match.params;
  // console.log(match.params);

  if (category !== 'notice' && category !== 'story') {
    return <ErrorPage />;
  }
  return (
    <>
      <FlHeader category={category} />
      <FdPostContainer category={category} postPk={postPk} />
      <Footer />
    </>
  );
};

export default FeedDetail;
