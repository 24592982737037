import React from 'react';
import { BLOCK_ALERT } from '../../../constants/DEFAULT_VALUE';
import { checkRegBlock } from '../../../utils/checkRegBlock';
import PFavoritesHeader from './PFavoritesHeader';

const FavoritesHeader = ({ regStatus, markedCount }) => {
  const handleReg = (value) => {
    // 수희등 기간에 현학기 수신 블락
    if (checkRegBlock(value)) {
      alert(BLOCK_ALERT);
      return;
    }
    window.location.href = `/favorites/${value}/`;
  };

  return (
    <PFavoritesHeader
      regStatus={regStatus}
      markedCount={markedCount}
      handleReg={handleReg}
    />
  );
};

export default FavoritesHeader;
