import styled, { css } from 'styled-components';

export const SearchPageContainer = styled.div`
  height: 100vh;
  min-height: -webkit-fill-available;
  background-color: #f7f7f7;
  padding-top: 100px;
`;
export const AdvancedSearchPageContainer = styled.div`
  height: 100vh;
  min-height: -webkit-fill-available;
  background-color: #f7f7f7;
  padding-top: ${(props) => (props.isTwoLine ? '110px' : '80px')};
  @media only screen and (max-width: 345px) {
    padding-top: ${(props) => (props.isTwoLine ? '100px' : '80px')};
  }
`;
export const AdvancedSearchBox = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 50px;
`;
export const SearchLoginBox = styled.div`
  margin-top: 25px;
  padding: 15px 20px 0px 20px;
  // background-color: #f7f7f7;

  div:nth-child(1) {
    // background-color: white;
  }
`;
export const SearchExpBox = styled.div`
  margin-top: 115px;
  padding: 15px 20px 0px 20px;
  // background-color: #f7f7f7;

  div:nth-child(1) {
    // background-color: white;
  }
`;
