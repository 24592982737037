import React, { useState, useCallback } from 'react';
import PSearchContainer from './PSearchContainer.jsx';

const SearchContainer = ({ queryText, regStatus, result, isPresent }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {result && (
        <PSearchContainer
          queryText={queryText}
          regStatus={regStatus}
          info={result}
          isPresent={isPresent}
        />
      )}
    </>
  );
};
export default SearchContainer;
