/* eslint-disable no-return-await */
import { API_URLS } from '../constants';
import { getToken } from './auth';
import { withAuthInstance, withoutAuthInstance } from './common';

const {
  GET_MAJOR,
  SIGNUP,
  USERINFO,
  USERINFO_SUM,
  EDIT_USERINFO,
  SIGNOUT,
  LOGIN_FROM_KOPAS,
} = API_URLS;

// 전공정보 관련기능
export const getMajorInfoApi = async () => {
  const newData = await withoutAuthInstance.get(GET_MAJOR);
  return newData;
};

// 계정 생성
export const signupApi = async (inputData) => {
  // console.log(inputData);
  const newData = await withoutAuthInstance.post(SIGNUP, inputData);
  return newData;
};

// 계정 정보 조회
export const getUserInfoApi = async () => {
  const newData = await withAuthInstance.get(USERINFO);
  return newData;
};

// 계정 요약 정보 조회
export const getUserInfoSumApi = async () => {
  const [accessToken, refreshToken] = getToken();
  if (accessToken && refreshToken) {
    const newData = await withAuthInstance.get(USERINFO_SUM);
    return newData;
  }
  const newData = await withoutAuthInstance.get(USERINFO_SUM);
  return newData;
};

// 계정 정보 수정
export const patchUserInfoApi = async (inputData) => {
  const newData = await withAuthInstance.patch(EDIT_USERINFO, inputData);
  return newData;
};
//

// 회원 탈퇴
export const signoutApi = async () => {
  const newData = await withAuthInstance.delete(SIGNOUT);
  return newData;
};

// 고파스 자동 로그인
export const OauthKopasApi = async (inputData) => {
  const newData = await withoutAuthInstance.post(LOGIN_FROM_KOPAS, inputData);
  return newData;
};
