/* eslint-disable object-shorthand */
import React, { useState } from 'react';

import { GoBack } from '../../Common';
import * as S from '../style';
import AdvancedSearchModal from '../../Modal/AdvancedSearchModal';

const AdvancedSearchHeader = ({ regStatus, info }) => {
  const { year, term, queryBy, queryText, clas } = info;
  const [isModalOn, setIsModalOn] = React.useState(false);
  const onFilterBtn = () => setIsModalOn(true);
  const modalInfo = {
    ...info,
    term: year.toString() + term.toString() || '',
    reg: regStatus || '',
    clas: clas.id || 0,
    cour: clas.courCd || 'all',
    col: clas.colCd || '',
    dept: clas.deptCd || '',
    search: queryBy || 'all',
    query: queryText || '',
    preCode: (queryText && queryText.split('-')[0]) || '',
    sufCode: (queryText && queryText.split('-')[1]) || '',
  };

  // console.log('modalInfo', modalInfo);
  // console.log('info', info);

  const FILTER_ICON_URL =
    'https://ku-sugang.s3.ap-northeast-2.amazonaws.com/icon/search-filter.svg';

  return (
    <S.AshContainer>
      <S.AshBackBtnFilterBox>
        {/* <S.AshBackBox>
          <GoBack />
        </S.AshBackBox> */}
        <S.AshFilterList>
          <S.AshFilterRow>
            <S.AshFilterItem>
              <p>
                {info.year}-{info.term}
              </p>
            </S.AshFilterItem>
            <S.AshFilterItem>
              <p>
                {regStatus === 'register' && '수강신청'}
                {regStatus === 'prereg' && '수강희망'}
              </p>
            </S.AshFilterItem>
            <S.AshFilterItem>
              <p>{info.clas.courNm || '전체'}</p>
            </S.AshFilterItem>
          </S.AshFilterRow>
          <S.AshFilterRow>
            {info.clas.colNm && !info.clas.deptNm && (
              <S.AshFilterItem>
                <p>{info.clas.colNm}</p>
              </S.AshFilterItem>
            )}
            {info.clas.deptNm && (
              <S.AshFilterItem>
                <p>{info.clas.deptNm}</p>
              </S.AshFilterItem>
            )}
            {info.queryBy && (
              <S.AshFilterItem>
                <p>
                  {(info.queryBy === 'name' && '과목명') ||
                    (info.queryBy === 'prof' && '교수명') ||
                    (info.queryBy === 'code' && '학수번호') ||
                    info.queryBy}
                  :{info.queryText}
                </p>
              </S.AshFilterItem>
            )}
          </S.AshFilterRow>
        </S.AshFilterList>
      </S.AshBackBtnFilterBox>
      <S.AshFilterIconImg
        className="advancedSearchOpen"
        src={FILTER_ICON_URL}
        onClick={onFilterBtn}
      />
      {isModalOn && (
        <AdvancedSearchModal
          setModalSwitch={setIsModalOn}
          searchInfo={modalInfo}
        />
      )}
    </S.AshContainer>
  );
};

export default AdvancedSearchHeader;
