import React from 'react';
import { FiRotateCw } from 'react-icons/fi';
import { HiOutlineRefresh } from 'react-icons/hi';
import { REG } from '../../../constants/DEFAULT_VALUE';
import * as S from '../style';

const PFavoritesHeader = ({ regStatus, markedCount, handleReg }) => {
  const refreshHandler = () => {
    window.location.reload();
  };

  return (
    <S.FhContainer>
      <S.FhName isNow={regStatus === REG}>
        <h2>
          {/* <span>{regStatus === 'register' ? '수강신청' : '수강희망'}</span>{' '} */}
          즐겨찾기한 과목
        </h2>
        <S.FhRedText>{markedCount}</S.FhRedText>
        <S.FhDarkGrayText>/10개</S.FhDarkGrayText>
        <FiRotateCw className="refresh" onClick={refreshHandler} />
      </S.FhName>
      <S.FhBottom>
        <S.FhRegWrapper regStatus={regStatus}>
          <S.FhRegButton
            value="prereg"
            onClick={(e) => handleReg(e.target.value)}
          >
            수강희망
            <div> </div>
          </S.FhRegButton>
          <S.FhRegButton
            value="register"
            onClick={(e) => handleReg(e.target.value)}
          >
            수강신청
            <div> </div>
          </S.FhRegButton>
        </S.FhRegWrapper>
      </S.FhBottom>
    </S.FhContainer>
  );
};

export default PFavoritesHeader;
