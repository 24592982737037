const API_URLS = {
  // account
  SIGNUP: '/account/users',
  SIGNOUT: '/account/users/me',
  USERINFO: '/account/users/me',
  USERINFO_SUM: 'account/users/summary',
  EDIT_USERINFO: '/account/users/me',
  GET_ALARM_STATE: '/account/noti',
  PATCH_ALARM_STATE: '/account/noti',
  GET_MAJOR: '/account/majors',
  // token
  TOKEN_OBTAIN: '/account/login',
  TOKEN_REFRESH: '/account/token/refresh',
  TOKEN_BLACKLIST: '/account/token/blacklist',
  LOGIN_FROM_KOPAS: '/account/login-from-kopas',

  // courseInfo
  // search
  GET_CLASS_SEARCH: '/course-info/search/',
  GET_ADVANCED_SEARCH: '/course-info/advanced-search/',
  GET_BOOKMARK: '/course-info/bookmarks/',
  CHANGE_BOOKMARK: '/course-info/bookmarks/',
  // detail Info
  GET_DETAIL_CLASSINFO: '/course-info/course/',
  GET_CLASS_PERIOD: '/course-info/period',
  // advanced-search
  GET_CLASSIFICATION: '/course-info/clas/',

  // feed
  GET_MAIN_INFO: '/feed/main/',
  GET_FEED_LIST: '/feed/',
  GET_FEED_DETAIL: '/feed/',
  LIKE_FEED: '/feed/',
  HIT_FEED: '/feed/hit/',
};

export default API_URLS;
