const MAJOR_TYPE_IN_KOR = {
  IS: '심화전공',
  DM: '이중전공',
  ID: '융합전공',
  DD: '복수전공',
  MM: '부전공',
  SD: '학생설계전공',
};

const getMajorTypeInKorean = (majorTypeAbbr) => {
  if (!Object.keys(MAJOR_TYPE_IN_KOR).includes(majorTypeAbbr)) {
    return '알 수 없음';
  }
  return MAJOR_TYPE_IN_KOR[majorTypeAbbr];
};

export default getMajorTypeInKorean;
