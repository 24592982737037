import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PSignupContainer from './PSignupContainer.jsx';
import {
  getMajorInfoApi,
  getUserInfoApi,
  signupApi,
  patchUserInfoApi,
} from '../../../utils/account.js';
import { Loading } from '../../Common/style.jsx';
import { YEAR } from '../../../constants/DEFAULT_VALUE.js';

const SignupContainer = ({ loc }) => {
  const [isLoading, setIsLoading] = useState(true);
  // 정규표현식
  const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s]+$/;
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({
    kopasUuid: '',
    nickname: '',
    admissionYear: '',
    grade: '',
    isNewTransfer: false,
    firstMajor: '',
    firstMajorCol: '',
    majorType: '',
    secondMajor: '',
    secondMajorCol: '',
  });
  const [majorList, setMajorList] = useState({
    iS: [],
    iD: [],
    sD: [],
  });

  const handleUserInfo = (e) => {
    const { id, value } = e.target;
    setUserInfo({
      ...userInfo,
      [id]: value,
    });
    if (id === 'firstMajorCol') {
      if (userInfo.majorType === 'IS') {
        setUserInfo({
          ...userInfo,
          [id]: value,
          firstMajor: '',
          secondMajorCol: value,
          secondMajor: '',
        });
      } else {
        setUserInfo({
          ...userInfo,
          [id]: value,
          firstMajor: '',
        });
      }
    } else if (id === 'firstMajor') {
      if (userInfo.majorType === 'IS') {
        setUserInfo({
          ...userInfo,
          [id]: value,
          secondMajor: value,
        });
      }
    } else if (id === 'majorType') {
      if (value === 'IS') {
        setUserInfo({
          ...userInfo,
          [id]: value,
          secondMajorCol: userInfo.firstMajorCol,
          secondMajor: userInfo.firstMajor,
        });
      } else {
        setUserInfo({
          ...userInfo,
          [id]: value,
          secondMajorCol: '',
          secondMajor: '',
        });
      }
    } else if (id === 'secondMajorCol') {
      setUserInfo({
        ...userInfo,
        [id]: value,
        secondMajor: '',
      });
    }
  };

  const handleCheckbox = (e) => {
    const { id, checked } = e.target;
    setUserInfo({
      ...userInfo,
      [id]: checked,
    });
  };

  const signup = async (userInfo) => {
    // console.log(userInfo);
    const [data, error] = await signupApi(userInfo);
    if (data) {
      localStorage.setItem('access-token', data.access);
      localStorage.setItem('refresh-token', data.refresh);
      localStorage.removeItem('uuid');
      alert(data.msg);
      history.replace('/account/mypage/');
    } else {
      console.log(error);
      alert('잘못된 요청입니다! 로그인 페이지로 돌아갑니다.');
      localStorage.removeItem('uuid');
      history.replace('/account/login/');
      // eslint-disable-next-line no-console
      console.dir(error);
    }
  };
  const edit = async (userInfo) => {
    // console.log(userInfo);
    const [data, error] = await patchUserInfoApi(userInfo);
    if (data) {
      alert(data.msg);
      history.replace('/account/mypage/');
    } else {
      // eslint-disable-next-line no-console
      console.dir(error);
      alert('잘못된 요청입니다! 로그인 페이지로 돌아갑니다.');
      history.replace('/account/login/');
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!regex.test(userInfo.nickname.replace(/ /g, ''))) {
      alert(`
      닉네임은 공백 없이 
      한글/영어/숫자만 
      입력 가능합니다
      `);
    } else if (window.confirm('정보 입력을 완료하셨나요?')) {
      if (loc === 'signup') {
        signup(userInfo);
      } else {
        edit(userInfo);
      }
    }
    // console.log(userInfo);
  };

  const gradeList = [];
  const yearList = [];
  for (let i = 1; i < 5; i += 1) {
    gradeList.push(i);
  }
  for (let i = YEAR; i >= 2000; i -= 1) {
    yearList.push(i);
  }
  const getMajorInfo = async () => {
    const [data, error] = await getMajorInfoApi();
    if (data) {
      setMajorList({ ...data });
      setIsLoading(false);
    } else {
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      alert('에러가 발생하였습니다.');
      history.replace('/account/login/');
      // eslint-disable-next-line no-console
      console.dir(error);
    }
  };
  const getUserInfo = async () => {
    const [data, error] = await getUserInfoApi(userInfo);
    if (data) {
      // console.log(data);
      setUserInfo({ ...data.user });
      // console.log(userInfo);
      getMajorInfo();
    } else {
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      alert(error.detail);
      history.replace('/account/login/');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (loc === 'signup') {
      // uuid 받아서 체크 해야함.
      if (!localStorage.getItem('uuid')) {
        alert('로그인 후에 사용가능합니다.');
        history.replace('/auth/login/');
      } else {
        getMajorInfo();
        setUserInfo({ ...userInfo, kopasUuid: localStorage.getItem('uuid') });
      }
    } else if (loc === 'edit') {
      getUserInfo();
    }
  }, []);

  if (isLoading || !userInfo || !majorList) {
    return <Loading />;
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading || (
        <PSignupContainer
          loc={loc}
          userInfo={userInfo}
          handleUserInfo={handleUserInfo}
          handleCheckbox={handleCheckbox}
          handleSubmit={handleSubmit}
          gradeList={gradeList}
          yearList={yearList}
          majorList={majorList}
        />
      )}
    </>
  );
};
export default SignupContainer;
