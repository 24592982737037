/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { GoBack } from '../../Common';
import * as S from '../style';

const TextBox = () => {
  return (
    <S.TextContainer>
      <GoBack />
      <S.LoginHeading>
        {' '}
        <span>고파스 아이디로</span>
        <p>로그인 하기!</p>
      </S.LoginHeading>
      <S.LoginText>
        {' '}
        실시간 수희등 경쟁률 비교부터 <br /> 과목별 빈자리 알림 발송까지!
      </S.LoginText>
    </S.TextContainer>
  );
};

export default TextBox;
