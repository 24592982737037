import React, { useState, useEffect, useCallback } from 'react';
import { BLOCK_ALERT, REG } from '../../../constants/DEFAULT_VALUE.js';
import { checkPresent } from '../../../utils/checkPresent.js';
import { checkRegBlock } from '../../../utils/checkRegBlock.js';
import PSearchHeader from './PSearchHeader.jsx';

const SearchHeader = ({ queryText, period, regStatus }) => {
  const [text, setText] = useState(queryText);

  const handleP = (e) => {
    // 수희등 기간에 현학기 수신 블락
    if (
      checkRegBlock(regStatus) &&
      checkPresent(e.target.value.substring(0, 4), e.target.value.substring(4))
    ) {
      alert(BLOCK_ALERT);
      return;
    }
    window.location.href = `/search/${regStatus}?year=${Number(
      e.target.value.substring(0, 4),
    )}&term=${Number(e.target.value.substring(4))}&queryText=${text.replace(
      / /g,
      '',
    )}`;
  };
  const handleR = (e) => {
    if (e.currentTarget.value === 'register') {
      // 수희등 기간에 현학기 수신 블락
      if (
        REG === 'prereg' &&
        checkPresent(period.substring(0, 4), period.substring(4))
      ) {
        alert(BLOCK_ALERT);
        return;
      }
      window.location.href = `/search/register?year=${Number(
        period.substring(0, 4),
      )}&term=${Number(period.substring(4))}&queryText=${text.replace(
        / /g,
        '',
      )}`;
    }
    if (e.currentTarget.value === 'prereg') {
      window.location.href = `/search/prereg?year=${Number(
        period.substring(0, 4),
      )}&term=${Number(period.substring(4))}&queryText=${text.replace(
        / /g,
        '',
      )}`;
    }
  };
  useEffect(() => {
    setText(queryText);
  }, [period, regStatus]);
  return (
    <PSearchHeader
      text={text}
      period={period}
      reg={regStatus}
      setText={setText}
      handleP={handleP}
      handleR={handleR}
    />
  );
};
export default SearchHeader;
