/* eslint-disable no-return-await */
import { API_URLS } from '../constants';
import { withAuthInstance } from './common';

const { GET_ALARM_STATE, PATCH_ALARM_STATE } = API_URLS;

// 알람 수신여부조회
export const getAlarmStateApi = async () => {
  return await withAuthInstance.get(GET_ALARM_STATE);
};
// 알람 수신 여부 변경
export const patchAlarmStateApi = async () => {
  return await withAuthInstance.patch(PATCH_ALARM_STATE);
};
