import React, { useState, useEffect } from 'react';
import PFlSection from './PFlSection.jsx';
import { getFeedListApi } from '../../../utils/feed';

const FlSection = ({ category }) => {
  // loading 시 주석 풀어주세용
  // const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const getPosts = async () => {
    const [data, error] = await getFeedListApi(category);
    if (data) {
      setPosts(data);
      // eslint-disable-next-line no-console
    } else console.dir(error);
  };

  useEffect(() => {
    getPosts();
    // loading 시 주석 풀어주세용
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000);
  }, []);
  return (
    <>
      {/* 로딩시 주석 풀어주세요!
      {isLoading && <Loading />} */}
      <PFlSection info={posts} />
    </>
  );
};

export default FlSection;
