import React from 'react';
import { SignupContainer, SignupTextBox } from '../../components/Signup';

const Signup = () => {
  return (
    <>
      <SignupTextBox loc="signup" />
      <SignupContainer loc="signup" />
    </>
  );
};

export default Signup;
