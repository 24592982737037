import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-height: 1500px;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BodyContainer = styled.div`
  animation: fadeIn 0.5s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 0.5rem;
  margin-bottom: 80px;
  padding: 0px 20px 15px 20px;
  a {
    width: 100%;
  }
  @media only screen and (max-width: 345px) {
    padding: 0px 15px 15px 15px;
    margin-top: 0.5rem;
  }
`;
export const CheckText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
export const TryLoginText = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #000000;
`;
export const TryLogin = styled.div`
  width: 120px;
  height: 30px;
  background: #484848;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FavoriteContainer = styled.div``;

export const NoticeBoard = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const MfRedText = styled.span`
  color: #c02e2e;
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 1rem;
  margin-right: 0.5rem;
  @media only screen and (max-width: 345px) {
    font-size: 1.1rem;
    margin-right: 0.2rem;
  }
`;

export const MfDarkGrayText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: #333333;
  @media only screen and (max-width: 345px) {
    font-size: 0.85rem;
  }
`;

export const BoardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  color: #000000;
  padding: 10px 0px 8px 0px;
  border-bottom: 1px solid #8080808f;
  svg {
    pointer-events: none;
  }
  & > a {
    width: 25px;
  }
  @media only screen and (max-width: 345px) {
    font-size: 1.1rem;
    svg {
      font-size: 25px;
    }
  }
`;
export const BoardInfo = styled.div`
  font-size: 12px;
  color: #767676;
  margin-top: 10px;
`;
export const BoardText = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  align-items: center;
  font-style: normal;
  font-size: 0.8em;
  line-height: 100%;
  color: #767676;
  padding-top: 10px;
  margin-bottom: 10px;
  @media only screen and (max-width: 345px) {
    font-size: 0.5rem;
    padding-right: 0px;
  }
  span {
    min-width: 164px;
  }
`;
export const BoardContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.5rem;
  width: 100%;
  height: 58px;
  background: #fafafa;
  border-radius: 10px;
  margin: 10px 0px;
  pointer-events: none;
`;
export const NoticeBoardTitle = styled.div`
  display: block;
  height: 1.5rem;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  color: #333333;
  word-break: keep-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 3px;
`;
export const StoryBoardTitle = styled.div`
  display: block;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const CreateAt = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #767676;
`;

export const SugangContents = styled.div`
  width: 100%;
  margin-top: 5px;
  @media only screen and (max-width: 345px) {
    margin-top: initial;
  }
`;

export const Contents = styled.div`
  width: 10rem;
  height: 14rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0.1rem 1.1rem 0 0;
  justify-content: space-around;
  img {
    object-fit: contain;
  }
  pointer-events: none;
`;

export const TotalContents = styled.div`
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  align-items: flex-end;
  width: -webkit-fill-available;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FillterImage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #484848;
`;

export const FavoritesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 0px 0px 0px;
`;
export const ContainerBox = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
    color: #e9e9e9;
  }
  @media only screen and (max-width: 345px) {
    margin-bottom: initial;
    svg {
      width: 70%;
    }
  }
`;
export const ContainerText = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #a0a0a0;
`;

export const FavoritesContents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  min-width: 150px;
  max-width: 156px;
  min-height: 174px;
  height: 78px;
  background: #fdf8f8;
  border-radius: 10px;
  margin: 0px 15px;
`;

export const CourseNum = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  height: 10%;
  color: #828282;
`;
export const CourseTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  margin-top: 30px;
  color: #333333;
`;
export const CoursePro = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  margin-top: 12px;
  color: #828282;
`;
export const ToalCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  height: 18%;
  color: #828282;
  position: relative;
  top: 20%;
  a {
    width: 20px;
    height: 100%;
    margin-left: 20px;
    margin-right: -5px;
    display: flex;
    align-items: center;
  }
`;
export const CountBox = styled.div`
  display: flex;
  align-items: center;
`;
export const CourseCount = styled.div`
  font-style: normal;
  margin: 0px 3px;
  font-weight: 500;
  font-size: 15px;
  color: ${({ isFull }) => (isFull ? '#C02E2E' : '#828282')};
`;
