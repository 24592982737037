import React from 'react';

import * as S from './style';
import Header from './Header';
import Menu from './Menu';

const LoggedInMyPagePresenter = ({
  userInfo,
  isNotiOn,
  notiToggleHandler,
  logoutHandler,
  signoutHandler,
}) => {
  const { notiCount, kopasUuid, nickname } = userInfo;
  return (
    <S.MypageWrapper>
      <Header
        userInfo={userInfo}
        isNotiOn={isNotiOn}
        kopasUuid={kopasUuid}
        notiToggleHandler={notiToggleHandler}
      />
      <Menu logoutHandler={logoutHandler} signoutHandler={signoutHandler} />
    </S.MypageWrapper>
  );
};

export default LoggedInMyPagePresenter;
