import React from 'react';
import { ConfirmModal } from '.';

import * as S from './style';

const CopyModal = ({ showSuccessModal, setShowSuccessModal }) => {
  // 링크 복사 성공 Modal
  // const pageUrl = useRef();
  // const copy = () => {
  //   const el = pageUrl.current;
  //   el.select();
  //   document.execCommand('copy');
  //   setShowSuccessModal(true);
  // };
  return (
    <S.CpmContainer>
      {showSuccessModal && (
        <ConfirmModal
          text="링크가 클립보드에 복사되었습니다."
          setShowSuccessModal={setShowSuccessModal}
          style={{ bottom: '90px' }}
        />
      )}
    </S.CpmContainer>
    // <S.CmBackdrop>
    //   <S.CmModalContainer>
    //     <S.CmModalHeader>
    //       <FiX onClick={() => setModalSwitch(false)} />
    //       <p>게시글 공유하기</p>
    //     </S.CmModalHeader>
    //     <S.CmModalContent>
    //       <S.CmLinkTextarea value={window.location.href} ref={pageUrl} />
    //       <S.CmCopyButton type="button" onClick={copy}>
    //         복사
    //       </S.CmCopyButton>
    //     </S.CmModalContent>
    //   </S.CmModalContainer>
    // </S.CmBackdrop>,

    // document.getElementById('modal'),
  );
};

export default CopyModal;
