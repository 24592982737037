import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineHeart, AiFillHeart, AiOutlineEye } from 'react-icons/ai';
import * as S from '../style';

const unLikedBtnStyle = {
  color: '#A0A0A0',
};
const isLikedBtnStyle = {
  color: '#C02E2E',
};

const NoticeSummary = ({ post }) => {
  return (
    <S.FlsWrapper>
      <S.FlsHeader>
        <S.FlsDtBox>{post.createdDay.substring(0, 10)}</S.FlsDtBox>
        <S.FlsSubInfoBox>
          {post.isLiked ? (
            <AiFillHeart style={isLikedBtnStyle} />
          ) : (
            <AiOutlineHeart style={unLikedBtnStyle} />
          )}
          <p>{post.likesCount}</p>
          <AiOutlineEye />
          <p>{post.hit}</p>
        </S.FlsSubInfoBox>
      </S.FlsHeader>
      <S.FlsSection>
        <S.FlsTitleBox>{post.title}</S.FlsTitleBox>
        <S.FlsContentBox>{post.content}</S.FlsContentBox>
      </S.FlsSection>
    </S.FlsWrapper>
  );
};

const StorySummary = ({ post }) => {
  return (
    <S.FlsStoryWrapper>
      <S.FlsStoryHeader imgUrl={post.firstImgUrl} />
      {/* <S.FlsImgBox>
        <img src={post.firstImgUrl} alt="스토리 사진" />
      </S.FlsImgBox> */}
      <S.FlsStorySection>
        <S.FlsSubBox>
          <S.FlsStoryTitle>{post.title}</S.FlsStoryTitle>
          <S.FlsDtBox>{post.createdDay.substring(0, 10)}</S.FlsDtBox>
        </S.FlsSubBox>
        <S.FlsSubInfoBox>
          {post.isLiked ? (
            <AiFillHeart style={isLikedBtnStyle} />
          ) : (
            <AiOutlineHeart style={unLikedBtnStyle} />
          )}
          <p>{post.likesCount}</p>
          <AiOutlineEye />
          <p>{post.hit}</p>
        </S.FlsSubInfoBox>
      </S.FlsStorySection>
    </S.FlsStoryWrapper>
  );
};

const PFlSection = ({ info }) => {
  return (
    <S.FlsContainer>
      {info.map((post) => {
        const { category } = post;
        const isNotice = category === 'Notice';
        return isNotice ? (
          <Link to={`/feed/notice/${post.id}`} key={post.id}>
            <NoticeSummary post={post} />
          </Link>
        ) : (
          <Link to={`/feed/story/${post.id}`} key={post.id}>
            <StorySummary post={post} />
          </Link>
        );
      })}
    </S.FlsContainer>
  );
};
export default PFlSection;
