import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const usePush = (path) => {
  const history = useHistory();
  return useCallback(() => {
    history.push(path);
  }, []);
};

export const useLocationAssign = (url) => {
  return useCallback(() => {
    window.location.assign(url);
  }, []);
};
