import styled from 'styled-components';
import { RiNotification4Line, RiBookmarkLine } from 'react-icons/ri';
import { FiChevronDown } from 'react-icons/fi';

export const BmContainer = styled.div`
  width: 280px;
  position: fixed;
  top: 0px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 150px;
  background: white;
  box-shadow: 0 5px 14px 0 rgb(0 0 0 / 19%);
  border-radius: 3px;
  padding-bottom: 12px;
  pre {
    margin-top: 20px;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    margin-bottom: 15px;
  }
  p {
    margin: 4px auto;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.16px;
    text-align: center;
    color: #080808;
  }
  // 아이콘
  svg {
    height: 42px;
    width: 42px;
    color: #8c0929;
  }
`;

export const ImContainer = styled.div`
  left: 0;
  background-color: greens;
  position: fixed;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  top: 0px;
  padding: 0 40px;
  justify-content: space-around;
  align-items: center;
`;

export const ImprevIconBox = styled.div`
  position: absolute;

  right: 85%;
  & > svg {
    cursor: pointer;
    font-size: -webkit-xxx-large;
  }
`;

export const ImnextIconBox = styled.div`
  position: absolute;
  left: 88%;
  & > svg {
    font-size: -webkit-xxx-large;
    cursor: pointer;
  }
`;

export const ImImgBox = styled.div`
  img {
    width: inherit;
    max-width: 450px;
  }
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImCloseBox = styled.div`
  position: absolute;
  top: 2%;
  right: 6%;
  & > svg {
    color: #8b0928;
    font-size: 51px;
    cursor: pointer;
  }
`;

export const ModalWrapper = styled.div`
  z-index: 99;
  width: 100%;
  max-width: 400px;
  @media only screen and (max-width: 450px) {
    width: 100%;
    max-width: 400px;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    max-width: 370px;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
    max-width: 350px;
  }
  @media only screen and (max-width: 370px) {
    width: 100%;
    max-width: 320px;
  }
  @media only screen and (max-width: 345px) {
    width: 100%;
    max-width: 300px;
  }
  @media only screen and (max-width: 310px) {
    width: 100%;
    max-width: 260px;
  }
  @media only screen and (max-width: 295px) {
    width: 100%;
    max-width: 245px;
  }
  @media only screen and (max-width: 280px) {
    width: 100%;
    max-width: 230px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ isCourse }) => (isCourse ? 'absolute' : 'fixed')};
  bottom: ${({ isCourse }) => (isCourse ? '-18%' : '9%')};
`;
export const ConfirmModal = styled.div`
  z-index: 99;
  // margin-left: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  max-width: 300px;
  height: 32px;
  background: #4f4f4f;
  border-radius: 18px;
  // font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 100%;
  color: #ffffff;
  @media only screen and (max-width: 345px) {
    font-size: 0.6rem;
    height: 30px;
  }
`;

export const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 3;
`;

export const Modal = styled.div`
  animation: fadeIn 0.4s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  max-width: 450px;
  min-width: 270px;
  display: flex;
  z-index: 0;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding: 20px 13px;
  height: auto;
  z-index: 100;
  width: 100%;
  overflow: hidden;
  background: white;

  @media only screen and (min-width: 1001px) {
    top: 10%;
    border-radius: 10px;
  }
  @media only screen and (max-width: 1000px) {
    bottom: 0%;
    border-radius: 10px 10px 0 0;
    position: fixed;
  }
  @media only screen and (max-width: 345px) {
    padding: 10px 5px;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 93%;
  border-bottom: 1px solid #828282;
  padding: 10px 0px 16px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  color: #000000;
  @media only screen and (max-width: 345px) {
    p {
      font-size: 0.95rem;
    }
  }
`;
export const ModalHeader = styled.div`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px 0;
  font-weight: 600;
  width: 80%;
  & > svg {
    font-size: 23px;
    cursor: pointer;
  }
  @media only screen and (max-width: 345px) {
    p {
      font-size: 0.95rem;
    }
  }
`;
export const SplitBox = styled.div`
  width: 23px;
`;
export const XBox = styled.div`
  cursor: pointer;
`;
export const ModalContent = styled.div`
  width: 93%;
`;
export const CalendarContent = styled.div`
  width: 95%;
`;
export const CalendarImg = styled.img`
  width: 100%;
`;

export const CalendarModal = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  max-width: 300px;
  height: 36px;
  bottom: 30px;
  background: #4f4f4f;
  border-radius: 18px;
  // font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: #ffffff;
`;

// 상세 검색 모달
export const AsmForm = styled.form`
  animation: fadeIn 0.5s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  &:valid button {
    background: black;
    cursor: pointer;
  }
`;
export const AsmOneWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AsmTwoWrapper = styled.div`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AsmHiddenTwoWrapper = styled.div`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
`;

export const AsmSmlWrapper = styled.div`
  overflow: hidden;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AsmSmlDiv = styled.div`
  width: 20px;
`;

export const AsmLabel = styled.label`
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #767676;
  margin-top: 30px;
  margin-bottom: 5px;
  padding-top: 5px;
`;

export const AsmSmlSelect = styled.select`
  background-color: rgba(255, 255, 255, 0);
  color: ${(props) => (props.value ? '#484848' : '#E9E9E9')};
  border: none;
  border-bottom: ${(props) =>
    props.value ? '1px solid #747474' : '1px solid #E9E9E9'};
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.44px;
  -webkit-appearance: none;
  border-radius: initial;
  text-align: left;
  text-decoration: none;
  padding: 6px 25px 6px 5px;
  outline: none;
  z-index: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  &:focus {
    border-bottom: 1px solid #8b0728;
  }
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`;

export const AsmBigSelect = styled.select`
  color: ${(props) => (props.value ? '#484848' : '#E9E9E9')};
  border: none;
  border-bottom: ${(props) =>
    props.value ? '1px solid #747474' : '1px solid #E9E9E9'};
  background-color: rgba(255, 255, 255, 0);
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.44px;
  text-align: left;
  text-decoration: none;
  -webkit-appearance: none;
  border-radius: initial;
  padding: 6px 0 6px 5px;
  outline: none;
  z-index: 1;
  &:focus {
    border-bottom: 1px solid #8b0728;
  }
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`;

export const AsmHiddenOneSelect = styled.select`
  color: ${(props) => (props.value ? '#484848' : '#E9E9E9')};
  border: none;
  border-bottom: 2px solid #747474;
  border-bottom: ${(props) =>
    props.value ? '1px solid #747474' : '1px solid #E9E9E9'};
  background-color: rgba(255, 255, 255, 0);
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.44px;
  text-align: left;
  text-decoration: none;
  -webkit-appearance: none;
  border-radius: initial;
  padding: 6px 0 6px 5px;
  outline: none;
  z-index: 1;
  margin-top: 13px;
  &:focus {
    border-bottom: 1px solid #8b0728;
  }
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`;

export const AsmQuery = styled.input`
  margin-top: 13px;
  color: ${(props) => props.value || '#E9E9E9'};
  border: none;
  border-bottom: ${(props) =>
    props.value ? '1px solid #747474' : '1px solid #E9E9E9'};
  padding: 6px 0 6px 5px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.44px;
  text-align: left;
  outline: none;
  color: #000000;
  text-decoration: none;
  &:focus {
    border-bottom: 1px solid #8b0728;
  }
  &::placeholder {
    color: #e9e9e9;
  }
  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`;

export const AsmBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
`;

export const AsmResetBtn = styled.div`
  cursor: pointer;
  background-color: initial;
  border: initial;
  color: #cfcfcf;
  font-size: 13px;
  font-weight: 600;
  img {
    width: 15px;
    margin: 0px 3px -3px 0px;
  }
`;

export const AsmSearchBtn = styled.button`
  font-size: 1.1rem;
  width: 65%;
  background: #cfcfcf;
  height: 55px;
  color: white;
  border: none;
  border-radius: 10px;
  margin: 25px 0;
  @media only screen and (max-width: 375px) {
    height: 45px;
    font-size: 0.9rem;
    margin: 15px 0;
  }
`;

export const AsmDownIconOne = styled(FiChevronDown)`
  font-size: 20px;
  color: #484848;
  position: absolute;
  right: 5px;
  top: 63px;
  z-index: 0;
`;

export const AsmDownIconTwo = styled(FiChevronDown)`
  font-size: 20px;
  color: #484848;
  position: absolute;
  right: 5px;
  bottom: 9px;
  z-index: 0;
  @media only screen and (max-width: 375px) {
    bottom: 2px;
  }
`;

// 과목 모달
export const CmBackdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
`;

export const CmContainer = styled.div`
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  ${({ isPast }) => {
    return isPast ? `height: 410px;` : `height: 460px;`;
  }}
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 2;
  /* box-shadow: 0 5px 14px 0 rgb(0 0 0 / 19%); */
  @media only screen and (max-width: 375px) {
    width: 260px;
    height: 400px;
  }
`;

export const CmWrapper = styled.div`
  width: 100%;
  ${({ isPast }) => {
    return isPast ? `height: 100%;` : `height: 90%;`;
  }}
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CmContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 45%;
`;

export const CmHeader = styled.div`
  position: relative;
  height: 9%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #828282;
`;

export const CmTitleBox = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: 700;
  align-self: center;
  font-size: 1rem;
  @media only screen and (max-width: 375px) {
    margin-right: 2.2rem;
    padding: 0 77px 0px 35px;
    p {
      margin-left: -0.3rem;
      font-size: 0.9rem;
      width: 150px;
    }
  }
`;

export const CmCloseBox = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  font-size: 1.4rem;
  color: #0d0d0d;
  align-self: self-end;
  & > svg {
    display: block;
    cursor: pointer;
  }
  @media only screen and (max-width: 375px) {
    font-size: 1.2rem;
  }
`;

export const CmSubInfoBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  font-size: 0.75rem;
  align-items: center;
`;

export const CmFavoritesIcon = styled(RiBookmarkLine)`
  color: #a0a0a0;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  margin-right: 0.2rem;
  align-self: center;
`;
export const CmNotificationIcon = styled(RiNotification4Line)`
  color: #a0a0a0;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  margin-right: 0.2rem;
  align-self: center;
`;

export const CmNotificationNum = styled.p`
  color: #a0a0a0;
  font-size: 0.9rem;
  align-self: center;
`;

export const CmMain = styled.div`
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CmCodeNotifBox = styled.div`
  position: relative;
`;
export const CmCodeBox = styled.div`
  line-height: 17px;
  cursor: pointer;
  color: #828282;
  font-size: 0.95rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    color: #c02e2e;
  }
  @media only screen and (max-width: 375px) {
    font-size: 0.9rem;
  }
`;

export const CmNameBox = styled.div`
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 375px) {
    font-size: 1rem;
  }
  &:hover {
    color: #c02e2e;
  }
`;

export const CmProfBox = styled.div`
  color: #828282;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 375px) {
    font-size: 0.8rem;
  }
`;

export const CmTimeRoomBox = styled.div`
  color: #828282;
  font-size: 0.8rem;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 375px) {
    font-size: 0.7rem;
  }
`;

export const CmRateBox = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 1rem;
    font-weight: 600;
    color: #333333;
    padding-right: 1rem;
  }
  span:nth-child(2) {
    font-size: 0.9rem;
    font-weight: 600;
    color: ${({ fullColor }) => (fullColor ? '#c02e2e' : 'blue')};
  }
  @media only screen and (max-width: 375px) {
    p {
      font-size: 0.9rem;
    }
    span:nth-child(2) {
      font-size: 0.8rem;
    }
  }
`;

export const CmTotalBox = styled.div`
  display: flex;
  align-items: center;
  p:nth-child(1) {
    font-size: 1rem;
    font-weight: 600;
    color: #333333;
    padding-right: 1rem;
  }
  span:nth-child(2) {
    font-size: 0.9rem;
    font-weight: 600;
    color: ${({ isFullCourse }) => (isFullCourse ? '#c02e2e' : 'blue')};
  }
  span:nth-child(3) {
    font-size: 1rem;
    font-weight: 500;
    color: #828282;
  }
`;

export const CmGradeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 50%;
`;

export const CmGradeBox = styled.div`
  font-size: 0.8rem;
  width: 25%;
  p:nth-child(1) {
    font-weight: 600;
    color: #333333;
  }
  span:nth-child(2) {
    font-weight: 600;
    color: ${({ isFullColor }) => (isFullColor ? '#c02e2e' : 'blue')};
  }
  span:nth-child(3) {
    font-weight: 500;
    color: #828282;
  }
`;

export const CmFooter = styled.div`
  cursor: pointer;
  width: 100%;
  height: 12%;
  color: white;
  background-color: ${(props) => (props.isBookmarked ? '#A0A0A0' : 'black')};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  p {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
  }
`;

export const CmBookmarkParagraph = styled.p`
  height: fit-content;
  cursor: pointer;
  font-size: 1.125rem;
  height: 22px;
`;

export const CmBookmarkBox = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
  align-items: center;
  height: 100%;
`;

// Copy Modal
export const CpmContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// 토글 버튼
export const AlarmToggleContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 12px;
`;

export const ToggleBtnWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-right: 1.25rem;
  @media only screen and (max-width: 375px) {
    margin-right: 0.8rem;
  }
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 2.1rem;
  height: 1.2rem;
  border-radius: 1.4rem;
  background: #cfcfcf;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 0.87rem;
    height: 0.87rem;
    margin: 0.15rem;
    background: #ffffff;
    box-shadow: 0.08rem 0.2rem 0.2rem 0.08rem rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CCheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 1rem;
  width: 1.5rem;
  height: 1rem;
  &:checked + ${CheckBoxLabel} {
    background: #c02e2e;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 0.9rem;
      height: 0.9rem;
      margin-left: 1.05rem;
      transition: 0.2s;
    }
  }
`;

export const AlarmToggleLabel = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 0.5rem;
  color: #a0a0a0;
  @media only screen and (max-width: 375px) {
    margin-right: 0.3rem;
  }
`;

export const CcFavoritesIcon = styled(RiBookmarkLine)`
  color: white;
  cursor: pointer;
  font-size: 1.4rem;
  margin-left: 0.3rem;
  align-self: center;
`;

export const CoursePlanLinkWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;

export const CoursePlanLink = styled.a`
  color: #a0a0a0;
  cursor: pointer;
  width: fit-content;
  font-size: 0.75rem;
  @media only screen and (max-width: 375px) {
    font-size: 0.7rem;
  }
  border-bottom: 0.1rem solid #a0a0a0;
  align-self: end;
  margin-top: 10px;
`;
