import React from 'react';
import PFlHeader from './PFlHeader.jsx';

const FlHeader = ({ category }) => {
  const dummyInfo = {
    category,
  };
  return <PFlHeader info={dummyInfo} />;
};
export default FlHeader;
