import { camelizeKeys, decamelizeKeys } from 'humps';
import { getToken, tokenRefresh } from '../auth';

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    (res) => {
      const [accessToken, refreshToken] = getToken();
      if (accessToken) {
        res.headers['x-access-token'] = `Bearer ${accessToken}`;
      }

      const newConfig = { ...res };
      newConfig.url = `${res.url}`;
      if (newConfig.headers['Content-Type'] === 'multipart/form-data')
        return newConfig;
      if (res.params) {
        newConfig.params = decamelizeKeys(res.params);
      }
      if (res.data) {
        newConfig.data = decamelizeKeys(res.data);
      }
      return newConfig;
    },
    (err) => Promise.reject(err),
  );
  instance.interceptors.response.use(
    (response) => {
      // console.dir(response.data);
      // caseConverter
      if (
        response.data &&
        response.headers['content-type'] === 'application/json'
      ) {
        response.data = camelizeKeys(response.data);
      }

      return [response.data, null];
    },
    async (error) => {
      const originalRequest = error.config;
      const [accessToken, refreshToken] = getToken();
      // Access Token 만료이거나 잘못된 경우
      if (error.response.status === 401 && !originalRequest.retry) {
        const [data, error] = await tokenRefresh(refreshToken);
        // Refresh 성공
        if (data && data.access) {
          originalRequest.retry = true;
          const { access: newAccessToken } = data;
          localStorage.setItem('access-token', newAccessToken);

          instance.defaults.headers['x-access-token'] = `Bearer ${accessToken}`;
          return instance(originalRequest);
        }
        // Refresh 실패 (Blank, Null, Expired, Not Valid)
        if (error) {
          alert('로그인이 만료되었습니다. 다시 로그인 해주세요.');
          localStorage.removeItem('access-token');
          localStorage.removeItem('refresh-token');
          window.location.href = `/account/login/`;
        }
      }
      return [null, error.response.data];
    },
  );
  return instance;
}
