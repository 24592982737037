/* eslint-disable prettier/prettier */
import React, { useEffect, useCallback } from 'react';
import { BiSearch } from 'react-icons/bi';
import * as S from './style';

const SearchBar = ({ text, period, reg, setText }) => {
  const getTextLength = (str) => {
    let len = 0;
    for (let i = 0; i < str.length; i += 1) {
      if (escape(str.charAt(i)).length === 6) {
        len += 1;
      }
      len += 1;
    }
    return len;
  };
  const onClick = useCallback(() => {
    setTimeout(() => {
      try {
        const query = text.replace(/ /g, '')
        if (query === '') {
          throw new Error('검색어를 입력하세요');
        }
        if (getTextLength(query) < 4) {
          throw new Error('한글은 2글자 이상, 영문은 4글자 이상 입력하세요');
        }
        window.location.href = `/search/${reg}?year=${Number(
          period.substring(0, 4),
        )}&term=${Number(period.substring(4))}&queryText=${query}`;
      } catch (e) {
        alert(e.message);
      }
    }, 10);
  }, [text, period, reg]);

  const onChange = (e) => {
    setText(e.target.value);
  };
  useEffect(() => {
    // onReset();
  }, [text, period, reg]);
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <S.SearchBarContainer>
      <S.SearchBar
        type="text"
        value={text}
        onChange={onChange}
        onKeyPress={(e) => onKeyPress(e)}
        placeholder="과목명, 학수번호, 교수명으로 과목을 검색해보세요"
      />
      <S.Searchbutton type="submit" onClick={() => onClick()}>
        <BiSearch />
      </S.Searchbutton>
    </S.SearchBarContainer>
  );
}

export default React.memo(SearchBar);
