import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import * as S from './style';

function EventPopup({ isOpenP, closePopup }) {
  useEffect(() => {
    if (isOpenP) {
      document.querySelector('html').style.overflow = 'hidden';
    }
  }, []);

  const closePopupAll = () => {
    if (window.confirm('팝업창을 하루동안 보지 않으시겠습니까?')) {
      localStorage.setItem('popupViewed1', Math.floor(new Date().getDate()));
      closePopup();
    }
  };

  return createPortal(
    <S.PuBackdrop>
      <S.PuContainer>
        <S.PuHeader>
          <p>수강신청 알리미 만족도 조사</p>
        </S.PuHeader>
        <S.PuMsgBox>
          <p className="subtitle">
            <a href="https://forms.gle/cc94iNQ94FZJ5sjQ8 ">
              만족도 조사하러 가기
            </a>
          </p>
          <pre>
            {' '}
            고려대학교 학우분들의 많은 관심과 사랑 <br />
            너무나도 감사드리며, 수강신청 알리미는 <br />
            2024년 1학기 수강신청 기간에 다시 돌아오겠습니다. <br /> <br />
            이에 더욱 나아진 서비스로 학우분들에게 <br />
            보답하기 위해 설문조사를 진행하고자 하니, <br />
            많은 참여 부탁드립니다.
            <br /> <br />☕ 상품 : <b>아메리카노 기프티콘 10개</b>
            <br />⌛ 소요 시간 : <b>1분 이내</b> <br />
            ✔️ 설문 기한 : <b>10월 6일 금요일 자정</b> <br />
            ✔️ 당첨자 발표 : <b>10월 7일 토요일</b>
          </pre>
        </S.PuMsgBox>
        <S.PuFooter>
          <button className="leftBox" type="button" onClick={closePopupAll}>
            오늘 하루 그만 보기
          </button>
          <button type="button" onClick={closePopup}>
            닫기
          </button>
        </S.PuFooter>
      </S.PuContainer>
    </S.PuBackdrop>,
    document.getElementById('modal'),
  );
}

export default EventPopup;
