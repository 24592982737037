import styled, { css } from 'styled-components';
import {
  RiBookmarkLine,
  RiBookmarkFill,
  RiNotificationOffLine,
  RiNotification4Fill,
} from 'react-icons/ri';
import { IoIosArrowBack } from 'react-icons/io';

// SearchHeader
export const ShContainer = styled.div`
  background-color: white;
  width: 100%;
  max-width: 450px;
  min-width: 275px;
  position: fixed;
  z-index: 2;
  top: 0;
  box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 16%);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 25px;
`;

export const ShTop = styled.div`
  display: flex;
  width: -webkit-fill-available;
  div:nth-child(2) {
    border-bottom: 1px solid #80808042;
    margin: 10px;
    padding-bottom: 5px;
    svg {
      font-size: 20px;
    }
  }
`;

export const ShBottom = styled.div`
  display: flex;
  place-content: space-between;
  margin: 0.2rem 0.3rem auto;
  button:nth-child(1) {
    margin-right: 15px;
  }
  @media only screen and (max-width: 345px) {
    button:nth-child(1) {
      margin-right: 0px;
    }
    margin-left: -5px;
  }
`;

export const ShRegWrapper = styled.div`
  width: 70%;
  ${(props) =>
    props.regStatus === 'register'
      ? css`
          button:nth-child(2) {
            div {
              visibility: visible;
              background-color: #c02e2e;
              width: 80%;
              height: 5px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              bottom: 0px;
              position: absolute;
              @media only screen and (max-width: 345px) {
                width: 75%;
              }
            }
            color: #c02e2e;
            font-weight: 700;
          }
        `
      : css`
          button:nth-child(1) {
            div {
              visibility: visible;
              background-color: #c02e2e;
              width: 80%;
              height: 5px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              bottom: 0px;
              position: absolute;
              @media only screen and (max-width: 345px) {
                width: 75%;
              }
            }
            color: #c02e2e;
            font-weight: 700;
          }
        `}
`;

export const ShRegBtn = styled.button`
  border: none;
  width: 73px;
  cursor: pointer;
  height: 2.9rem;
  color: #828282;
  background-color: white;
  padding: 0 0.5rem;
  border-radius: 18px 18px 0 0;
  font-weight: 600;
  font-size: 0.9rem;
  position: relative;
  @media only screen and (max-width: 345px) {
    font-size: 0.7rem;
  }
`;

export const ShFilterWrapper = styled.div`
  align-self: center;
  svg {
    font-size: 20px;
    color: #767676;
  }
  select {
    width: 95px;
  }
  @media only screen and (max-width: 345px) {
    width: 27%;
    select {
      width: 85px;
    }
  }
`;

export const ShFilter = styled.select`
  width: 110px;
  border: none;
  -webkit-appearance: none;
  text-decoration: none;
  background: url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/icon/filter.svg')
    no-repeat 100%;
  color: #484848;
  font-size: 0.8rem;
  cursor: pointer;

  @media only screen and (max-width: 345px) {
    font-size: 0.7rem;
  }
`;

// AdvancedSearchHeader
export const AshContainer = styled.div`
  background-color: white;
  width: 100%;
  height: fit-content;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 16%);
  width: 100%;
  max-width: 450px;
  min-width: 270px;
  position: fixed;
  top: 0;
  z-index: 1;
  @media only screen and (max-width: 345px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const AshBackBox = styled.div`
  width: 2rem;
  margin: 0.5rem 0.5rem 0 0;
  div:nth-child(2) {
    border-bottom: 1px solid #80808042;
    margin: 10px;
    padding-bottom: 5px;
    svg {
      font-size: 20px;
    }
  }
`;
export const AshBackBtnFilterBox = styled.div`
  display: flex;
`;

export const AshFilterIconImg = styled.img`
  position: relative;
  left: 0.35rem;
  width: 3rem;
  margin-bottom: 1rem;
  cursor: pointer;
  @media only screen and (max-width: 345px) {
    width: 2.8rem;
  }
`;

export const AshFilterIcon = styled.svg`
  font-size: 2rem;
`;

export const AshFilterList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const AshFilterRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const AshFilterItem = styled.li`
  color: #484848;
  font-size: 0.7rem;
  padding: 0.5rem 1rem;
  margin: 0 0.2rem 0.5rem 0.2rem;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  text-align: center;
  min-width: 2.5rem;
  p {
    padding: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
  }
  @media only screen and (max-width: 345px) {
    font-size: 0.6rem;
    padding: 0.3rem 0.6rem;
    border-radius: 8px;
  }
`;

// SearchContainer
export const ScContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
  background-color: #f7f7f7;
`;

export const ScWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0.1rem 1.3rem 5.8rem 1.3rem;
  background-color: #f7f7f7;
  p {
    color: #4f4f4f;
    padding: 0.625rem 0;
    font-size: 0.85rem;
  }
  @media only screen and (max-width: 345px) {
    padding: 0.1rem 1rem 5.8rem 1rem;
    p {
      font-size: 0.7rem;
    }
  }
`;

export const ScTitle = styled.h1`
  font-size: 1.3rem;
  border-bottom: 1.5px solid #80808042;
  padding: 0 0 0.8rem;
  @media only screen and (max-width: 345px) {
    font-size: 1rem;
    padding: 0 0 0.5rem;
  }
`;

export const ScResultMsg = styled.p`
  color: #4f4f4f;
`;

// SearchSection
export const SsContainer = styled.div`
  animation: fadeIn 0.5s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  display: grid;
  margin: 0.5rem 0 2rem 0;
  column-gap: 1rem;
  row-gap: 1rem;
  @media only screen and (max-width: 345px) {
    column-gap: 0.7rem;
    row-gap: 0.7rem;
  }
  @media only screen and (max-width: 280px) {
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
`;
export const IsMoreBtn = styled.div`
  width: webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
`;

// SearchBar
export const SearchBarContainer = styled.div`
  display: flex;
  width: -webkit-fill-available;
`;

export const SearchBar = styled.input`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  padding: 0 8px;
  letter-spacing: 0.8px;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background: none;
  outline: none;
  text-decoration: none;
  ::placeholder {
    color: #a0a0a0;
  }
  @media only screen and (max-width: 345px) {
    font-size: 12px;
    padding: 0 5px;
  }
`;

export const Searchbutton = styled.button`
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

// CourseCard
export const CcContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 0.85rem;
  padding-right: ${({ ismain }) => ismain && '0'};
  height: -webkit-fill-available;
  max-height: 165px;
  margin: ${({ ismain }) => ismain && '0 1rem 0 0'};
  width: ${({ ismain }) => ismain && '9.2rem'};
  min-width: ${({ ismain }) => ismain && '9.2rem'};
  height: ${({ ismain }) => ismain && '10rem'};
  background-color: ${({ ismain }) => (ismain ? '#FDF8F8' : 'white')};

  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 345px) {
    justify-content: space-between;
    height: ${({ ismain }) => (ismain ? '8rem' : '6.5rem')};
    width: ${({ ismain }) => ismain && '123px'};
    min-width: ${({ ismain }) => ismain && '123px'};
    padding: 0.6rem;
    padding-right: ${({ ismain }) => ismain && '0'};
  }
`;

export const CcHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${({ ismain }) => (ismain ? '16%' : '20%')};
  @media only screen and (max-width: 345px) {
    height: 17%;
  }
`;

export const CcNotifBookmarkBox = styled.div`
  display: flex;
`;

export const CcNumBox = styled.div`
  cursor: pointer;
  color: #828282;
  font-size: 1rem;
  align-self: center;
  @media only screen and (max-width: 345px) {
    font-size: ${({ ismain }) => (ismain ? '0.8rem' : '0.85rem')};
  }
  &:hover {
    color: #c02e2e;
  }
`;

export const CcBookMarkBox = styled.div`
  text-align: center;
  margin-left: 0.625rem;
`;

export const CcFavoritesFillIcon = styled(RiBookmarkFill)`
  color: #c02e2e;
  cursor: pointer;
  font-size: ${({ ismain }) => (ismain ? '1.375rem' : '1.7rem')};
  align-self: center;
  @media only screen and (max-width: 345px) {
    font-size: 1.2rem;
  }
`;

export const CcFavoritesIcon = styled(RiBookmarkLine)`
  color: #a0a0a0;
  cursor: pointer;
  font-size: 1.7rem;
  align-self: center;
  @media only screen and (max-width: 345px) {
    font-size: 1.2rem;
  }
`;

export const CcBookmarkedNumBox = styled.div`
  color: ${({ isBookmarked }) => (isBookmarked ? '#c02e2e' : '#a0a0a0')};
  font-size: 0.6rem;
  line-height: 1;
`;

export const CcNotifBox = styled.div`
  text-align: center;
`;

export const CcNotifOffIcon = styled(RiNotificationOffLine)`
  color: #a0a0a0;
  cursor: pointer;
  font-size: ${({ ismain }) => (ismain ? '1.375rem' : '1.7rem')};
  align-self: center;
  @media only screen and (max-width: 345px) {
    font-size: 1.2rem;
  }
`;

export const CcNotifOnIcon = styled(RiNotification4Fill)`
  color: #c02e2e;
  cursor: pointer;
  font-size: ${({ ismain }) => (ismain ? '1.375rem' : '1.7rem')};
  align-self: center;
  @media only screen and (max-width: 345px) {
    font-size: 1.2rem;
  }
`;

export const CcNotifNumBox = styled.div`
  display: ${({ ismain }) => ismain && 'none'};
  color: ${({ isNotiOn }) => (isNotiOn ? '#c02e2e' : '#a0a0a0')};
  font-size: 0.6rem;
  line-height: 1;
`;

export const CcMain = styled.div`
  margin: 0;
  @media only screen and (max-width: 345px) {
    margin-bottom: initial;
  }
`;

export const CcNameBox = styled.div`
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  font-weight: 700;
  font-size: ${({ ismain }) => (ismain ? '0.9rem' : '1.15rem')};
  line-height: 1.3;
  color: #333333;
  margin: 0.4rem 0;
  width: 92%;
  @media only screen and (max-width: 345px) {
    font-size: ${({ ismain }) => (ismain ? '0.8rem' : '0.95rem')};
    margin: 0.2rem 0;
    width: 80%;
  }
  &:hover {
    color: #c02e2e;
  }
`;

export const CcProfBox = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  width: 85%;
  font-weight: 500;
  font-size: 0.825rem;
  line-height: 1.3;
  color: #828282;
  @media only screen and (max-width: 345px) {
    font-size: ${({ ismain }) => (ismain ? '0.6rem' : '0.8rem')};
  }
`;

export const CcFooter = styled.div`
  display: flex;
  margin-top: ${({ ismain }) => ismain && '1rem'};
  justify-content: ${({ regStatus, ismain }) =>
    regStatus === 'prereg' || ismain ? 'space-between' : 'flex-end'};
  @media only screen and (max-width: 345px) {
    height: 25%;
  }
`;

export const CcRateBox = styled.div`
  display: flex;
  width: ${({ ismain }) => (ismain ? '100%' : '90%')};
  justify-content: ${({ ismain }) => (ismain ? 'flex-start' : 'space-between')};
  flex-direction: ${({ ismain }) => (ismain ? 'column' : 'row')};
`;

export const CcRateContent = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ ismain }) => (ismain ? '1rem' : '1.375rem')};
`;

export const CcMoreInfoBtn = styled.img`
  align-self: flex-end;
  width: 28px;
  font-size: ${({ ismain }) => (ismain ? '1.375rem' : '1.7rem')};
  color: #333333;
  cursor: pointer;
  @media only screen and (max-width: 345px) {
    font-size: 0.8rem;
  }
`;

export const CcDarkGraySpan = styled.span`
  color: #4f4f4f;
  font-size: ${({ ismain }) => (ismain ? '0.7rem' : '1rem')};
  padding-right: ${({ ismain }) => (ismain ? '0.3rem' : '0.6rem')};
  @media only screen and (max-width: 345px) {
    font-size: ${({ ismain }) => (ismain ? '0.5rem' : '0.8rem')};
    padding-right: ${({ ismain }) => (ismain ? '0.2rem' : '0.3rem')};
  }
`;

export const CcGradeSpan = styled.span`
  color: ${({ gradeColor }) => (gradeColor ? '#c02e2e' : 'blue')};
  font-weight: 600;
  font-size: ${({ ismain }) => (ismain ? '1.05rem' : '1.3rem')};
  @media only screen and (max-width: 345px) {
    font-size: ${({ ismain }) => (ismain ? '0.75rem' : '0.9rem')};
  }
`;

export const CcRedSpan = styled.span`
  color: ${({ isTotalColor }) => (isTotalColor ? '#c02e2e' : 'blue')};
  font-size: ${({ ismain }) => (ismain ? '1.05rem' : '1.3rem')};
  font-weight: 600;
  @media only screen and (max-width: 345px) {
    font-size: ${({ ismain }) => (ismain ? '0.75rem' : '0.9rem')};
  }
`;

export const CcGraySpan = styled.span`
  color: #828282;
  font-size: ${({ ismain }) => (ismain ? '1.05rem' : '1.3rem')};
  font-weight: 500;
  @media only screen and (max-width: 345px) {
    font-size: ${({ ismain }) => (ismain ? '0.75rem' : '0.9rem')};
  }
`;

// AdvancedSearchContainer

export const AscContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: #f7f7f7;
  position: relative;
`;

export const AscSortResultTextBox = styled.div`
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 345px) {
    // flex-direction: column;
    p {
      padding: initial;
    }
    div {
      line-height: 13px;
      text-align: end;
      width: 25%;
    }
  }
`;

export const AscParagraph = styled.p`
  color: #4f4f4f;
  padding: 0.625rem 0;
  font-size: 0.85rem;
`;
