import React from 'react';
import { SignupContainer, SignupTextBox } from '../../components/Signup';

const Edit = () => {
  return (
    <>
      <SignupTextBox loc="edit" />
      <SignupContainer loc="edit" />
    </>
  );
};

export default Edit;
