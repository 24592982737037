/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { GoBack } from '../../Common';
import * as S from '../style';

const SignupTextBox = ({ loc }) => {
  // console.log(loc);
  return (
    <S.TextContainer>
      <GoBack />
      <S.LoginHeading>
        {loc === 'signup' ? (
          <span>
            나의 정보를
            <br /> 입력해주세요.
          </span>
        ) : (
          <>
            <span>내 정보 수정</span>
            <S.LineBreak>
              <hr />
            </S.LineBreak>
          </>
        )}
      </S.LoginHeading>
      <S.LoginText>
        학년별로 알림 서비스의 내용이 달라집니다.
        <br />
        정확한 정보를 입력해주세요!
      </S.LoginText>
    </S.TextContainer>
  );
};

export default SignupTextBox;
