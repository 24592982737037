import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DevText = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  margin-top: 25px;
  text-align: center;
`;
export const DevSubText = styled.div`
  margin-top: 30px;
  color: #cfcfcf;
  font-weight: 500;
  font-size: 17px;
  justify-content: center;
  text-align: center;
`;

export const DevLinkText = styled.a`
  margin-top: 30px;
  font-weight: 500;
  color: gray;
  text-decoration: underline;
  font-size: 18px;
  justify-content: center;
  text-align: center;
`;
