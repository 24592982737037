import React from 'react';

import * as S from '../style';

const PCourseCard = ({
  regStatus,
  course,
  openModal,
  ismain,
  isBookMarker,
  isNotif,
  onClickCourse,
}) => {
  return (
    <S.CcContainer ismain={ismain}>
      <S.CcHeader ismain={ismain}>
        <S.CcNumBox className="courseCode" onClick={onClickCourse}>
          {/* <S.CcNumBox className="courseCode"> */}
          {course.courseInfo.courCd}-{course.courseInfo.courCls}
        </S.CcNumBox>
        {(regStatus === 'prereg' && ismain) || (
          <S.CcNotifBookmarkBox>
            {isNotif}
            {isBookMarker}
          </S.CcNotifBookmarkBox>
        )}
      </S.CcHeader>
      <S.CcMain>
        <S.CcNameBox
          className="courseName"
          ismain={ismain}
          onClick={onClickCourse}
        >
          {/* <S.CcNameBox className="courseName" ismain={ismain}> */}
          {course.courseInfo.courNm}
        </S.CcNameBox>
        <S.CcProfBox>{course.courseInfo.profNm}</S.CcProfBox>
      </S.CcMain>
      {regStatus === 'register' ? (
        <S.CcFooter regStatus={regStatus} ismain={ismain}>
          <S.CcRateContent ismain={ismain}>
            <S.CcRedSpan
              isTotalColor={course.totalStatus.isFull}
              ismain={ismain}
            >
              {course.totalStatus.totalApply}
            </S.CcRedSpan>
            <S.CcGraySpan ismain={ismain}>
              /{course.totalStatus.totalLimit}
            </S.CcGraySpan>
          </S.CcRateContent>
          <S.CcMoreInfoBtn
            src="https://ku-sugang.s3.ap-northeast-2.amazonaws.com/icon/more.svg"
            className="courseDetail"
            onClick={() =>
              openModal(
                course.courseInfo.year,
                course.courseInfo.term,
                course.courseInfo.id,
              )
            }
            ismain={ismain}
          />
        </S.CcFooter>
      ) : (
        <S.CcFooter regStatus={regStatus} ismain={ismain}>
          <S.CcRateBox ismain={ismain}>
            <S.CcRateContent>
              <S.CcDarkGraySpan ismain={ismain}>
                {course.gradeStatus.grade}학년
              </S.CcDarkGraySpan>
              <S.CcGradeSpan
                ismain={ismain}
                gradeColor={
                  course.gradeStatus.gradeApply >= course.gradeStatus.gradeLimit
                }
              >
                {course.gradeStatus.gradeApply}
              </S.CcGradeSpan>
              <S.CcGraySpan ismain={ismain}>
                /{course.gradeStatus.gradeLimit}
              </S.CcGraySpan>
            </S.CcRateContent>
            <S.CcRateContent ismain={ismain}>
              <S.CcDarkGraySpan ismain={ismain}>전체 경쟁률</S.CcDarkGraySpan>
              <S.CcRedSpan
                ismain={ismain}
                isTotalColor={course.gradeStatus.isFull}
              >
                {course.gradeStatus.rate}:1
              </S.CcRedSpan>
            </S.CcRateContent>
          </S.CcRateBox>
          <S.CcMoreInfoBtn
            src="https://ku-sugang.s3.ap-northeast-2.amazonaws.com/icon/more.svg"
            className="courseDetail"
            onClick={() =>
              openModal(
                course.courseInfo.year,
                course.courseInfo.term,
                course.courseInfo.id,
              )
            }
          />
        </S.CcFooter>
      )}
    </S.CcContainer>
  );
};
export default PCourseCard;
