import React, { useState, useEffect, useContext } from 'react';
import PMainFavorites from './PMainFavorites.jsx';
import { getMyBookmarksApi } from '../../../utils/class';
import Loading from '../../Common/Loading.jsx';
import { BLOCK_ALERT, REG } from '../../../constants/DEFAULT_VALUE.js';

const MainFavorites = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFavorite, setIsFavorite] = useState(REG);
  const [favInfo, setFavInfo] = useState([]);
  const [changeURL, setChangeURL] = useState('');
  const handleP = (e) => {
    if (isFavorite !== e.target.value) {
      // 수희등 기간에 현학기 수신 블락
      if (REG === 'prereg') {
        alert(BLOCK_ALERT);
        setIsFavorite(REG);
      } else {
        setIsFavorite(e.target.value);
        setIsLoading(true);
      }
    }
  };

  const favorData = async (isFavorite) => {
    const [data, error] = await getMyBookmarksApi(isFavorite);
    if (data) {
      setFavInfo(data);
      setIsLoading(false);
    } else {
      // eslint-disable-next-line no-console
      console.dir(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    setChangeURL(`/favorites/${isFavorite}`);
    favorData(isFavorite);
  }, [isFavorite]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PMainFavorites
      favInfo={favInfo}
      isFavorite={isFavorite}
      changeURL={changeURL}
      handleP={handleP}
    />
  );
};

export default MainFavorites;
