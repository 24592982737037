import React from 'react';
import { Link } from 'react-router-dom';
import { RiNotification4Line } from 'react-icons/ri';

import * as S from './style';
import { getMajorTypeInKorean, usePush } from '../../../utils';
import { getNotiBoxURL } from '../../../constants';

const LoggedInHeader = ({
  userInfo,
  isNotiOn,
  kopasUuid,
  notiToggleHandler,
}) => {
  const {
    nickname,
    grade,
    admissionYear,
    isNewTransfer,
    firstMajor,
    secondMajor,
    majorType,
  } = userInfo;
  const admissionNum = admissionYear.toString().slice(-2);
  const pushToEdit = usePush('/account/edit/');
  const goNotiBox = () => window.location.assign(getNotiBoxURL(kopasUuid));

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <S.Container>
        <S.MyHeader>
          <S.Headline>
            <S.P>{nickname}</S.P>
            <S.editBtn onClick={pushToEdit}>편집</S.editBtn>
          </S.Headline>
          <S.MoveToLeftDiv>
            <S.UserInfoContainer>
              <S.infoItem>#{grade}학년</S.infoItem>
              <S.infoItem>#{admissionNum}학번</S.infoItem>
              {isNewTransfer && <S.infoItem>#신/편입생</S.infoItem>}
              <S.infoItem>#{firstMajor}</S.infoItem>
              {majorType === 'IS' || <S.infoItem>#{secondMajor}</S.infoItem>}
              <S.infoItem>#{getMajorTypeInKorean(majorType)}</S.infoItem>
            </S.UserInfoContainer>
          </S.MoveToLeftDiv>
        </S.MyHeader>
        <S.MyAlarmBox>
          <S.MyAlarmTextBox>
            <S.MyAlarmHeader>
              <S.AlarmWordWrapper>
                <p>전체 푸시 알림</p>
                <RiNotification4Line />
              </S.AlarmWordWrapper>
              <S.ToggleBtnWrapper>
                <S.CheckBox checked={isNotiOn} id="checkbox" type="checkbox" />
                <S.CheckBoxLabel
                  htmlFor="checkbox"
                  onClick={notiToggleHandler}
                />
              </S.ToggleBtnWrapper>
            </S.MyAlarmHeader>
            <S.MyAlarmFooter>
              <a
                href="https://www.koreapas.com/m/go_alimi.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                현재까지 받은 알림 확인하기 &gt;{' '}
              </a>
            </S.MyAlarmFooter>
          </S.MyAlarmTextBox>
        </S.MyAlarmBox>
      </S.Container>
    </>
  );
};

export default React.memo(LoggedInHeader);
