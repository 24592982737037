import React, { useMemo } from 'react';

import { FiArrowUpRight } from 'react-icons/fi';
import * as S from './style';
import { usePush } from '../../../utils';

const LoggedOutHeader = () => {
  const pushToLogin = usePush('/account/login/');

  return (
    <S.HeaderContainer>
      <S.Headline onClick={pushToLogin}>
        <S.P className="login">로그인하기</S.P>
        <FiArrowUpRight
          style={useMemo(() => ({
            cursor: 'pointer',
            fontSize: '2rem',
            placeSelf: 'end',
          }))}
        />
      </S.Headline>
      <S.P2>로그인 후 다양한 기능을 이용해보세요.</S.P2>
    </S.HeaderContainer>
  );
};

export default LoggedOutHeader;
