import React from 'react';
import * as S from '../style';

const SubLink = () => {
  const goSignup = () => {
    window.location.assign(
      'https://www.koreapas.com/bbs/member_join.php?group_no=1',
    );
  };
  const goFind = () => {
    window.location.assign('https://www.koreapas.com/bbs/lostid_new.php');
  };
  return (
    <S.LinkBox>
      <S.Link onClick={goSignup}>
        {' '}
        <p>고파스 회원가입 </p>
      </S.Link>
      <p>|</p>
      <S.Link onClick={goFind}>
        {' '}
        <p>아이디/비밀번호 찾기</p>
      </S.Link>
    </S.LinkBox>
  );
};

export default SubLink;
