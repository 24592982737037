import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowUpRight } from 'react-icons/fi';
import * as S from './style';
import { GoLogin, GoExp } from '../../Common';
import { MainFavorites } from '..';

const PMainContents = ({ isLogin, noticeList, storyList, setIsLoading }) => {
  return (
    <S.Wrapper>
      <S.BodyContainer>
        {isLogin ? (
          <>
            <GoExp />
            <MainFavorites setIsLoading={setIsLoading} />
          </>
        ) : (
          <GoLogin />
        )}
        <S.FavoriteContainer />
        <S.NoticeBoard>
          <S.BoardTitle>
            공지사항
            <Link to="/feed/notice">
              <FiArrowUpRight fontSize="30px" color="black" />
              {/* <img src="/arrow.png" alt="error" width="12px" height="12px" /> */}
            </Link>
          </S.BoardTitle>
        </S.NoticeBoard>
        {noticeList}

        <S.SugangContents>
          <S.BoardTitle>
            수알 스토리
            <Link to="/feed/story">
              <FiArrowUpRight fontSize="30px" color="black" />
            </Link>
          </S.BoardTitle>
        </S.SugangContents>
        <S.TotalContents>{storyList}</S.TotalContents>
      </S.BodyContainer>
    </S.Wrapper>
  );
};
export default PMainContents;
