/* eslint-disable import/no-named-as-default */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import PFdPostContainer from './PFdPostContainer';
import { getFeedDetailApi } from '../../../utils/feed';

const FdPostContainer = ({ category, postPk }) => {
  const [postDetail, setPostDetail] = useState([]);
  const getDetail = async () => {
    const [data, error] = await getFeedDetailApi(category, postPk);
    if (data) {
      setPostDetail(data);
      // eslint-disable-next-line no-console
    } else console.dir(error);
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <PFdPostContainer
      category={category}
      postPk={postPk}
      postDetail={postDetail}
    />
  );
};

export default FdPostContainer;
