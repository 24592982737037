/* eslint-disable react/no-array-index-key */
import React from 'react';
import * as S from '../style';

const PSignupContainer = ({
  loc,
  userInfo,
  handleUserInfo,
  handleCheckbox,
  handleSubmit,
  gradeList,
  yearList,
  majorList,
}) => {
  return (
    <S.Signupform onSubmit={handleSubmit}>
      <S.Signuplabel htmlFor="nickname">닉네임</S.Signuplabel>
      <S.Signupnickname
        id="nickname"
        value={userInfo.nickname.replace(/ /g, '')}
        onChange={handleUserInfo}
        placeholder="닉네임 입력"
        maxLength={8}
        required
      />

      <S.SignupfirstWrapper>
        <S.SignupGroupWrapper>
          <S.Signuplabel htmlFor="grade">학년</S.Signuplabel>
          <S.SignupDownIcon />
          <S.SignupSmlSelect
            value={userInfo.grade}
            id="grade"
            onChange={handleUserInfo}
            required
          >
            <option value="" disabled hidden>
              학년을 선택해주세요.
            </option>
            {gradeList.map((grade, idx) => (
              <option value={grade} key={idx}>
                {grade}학년
              </option>
            ))}
          </S.SignupSmlSelect>
        </S.SignupGroupWrapper>
        <S.SignupDiv />
        <S.SignupGroupWrapper>
          <S.Signuplabel htmlFor="admissionYear">학번</S.Signuplabel>
          <S.SignupDownIcon />
          <S.SignupSmlSelect
            id="admissionYear"
            value={userInfo.admissionYear}
            setDisplayCount="5"
            onChange={handleUserInfo}
            required
          >
            <option value="" disabled hidden>
              학번을 선택해주세요.
            </option>
            {yearList.map((year, idx) => (
              <option value={year} key={idx}>
                {String(year - 2000).padStart(2, '0')}학번
              </option>
            ))}
          </S.SignupSmlSelect>
        </S.SignupGroupWrapper>
        <S.SignupDiv />
        <S.SignupCheckWrapper>
          <S.Signuplabel htmlFor="isNewTransfer">신/편입생</S.Signuplabel>
          <S.SignupCheckbox
            id="isNewTransfer"
            type="checkbox"
            checked={userInfo.isNewTransfer}
            onChange={handleCheckbox}
          />
        </S.SignupCheckWrapper>
      </S.SignupfirstWrapper>

      <S.SignupGroupWrapper>
        <S.Signuplabel htmlFor="firstMajorCol">제1전공 대학/학부</S.Signuplabel>
        <S.SignupDownIcon />
        <S.SignupBigSelect
          id="firstMajorCol"
          onChange={handleUserInfo}
          value={userInfo.firstMajorCol}
          required
        >
          <option value="" disabled hidden>
            대학/학부를 선택해주세요.
          </option>
          {Object.keys(majorList.iS).map((col, idx) => (
            <option value={col} key={idx}>
              {col}
            </option>
          ))}
        </S.SignupBigSelect>
      </S.SignupGroupWrapper>
      <S.SignupGroupWrapper>
        <S.Signuplabel htmlFor="firstMajor">제1전공 학과</S.Signuplabel>
        <S.SignupDownIcon />
        <S.SignupBigSelect
          id="firstMajor"
          onChange={handleUserInfo}
          value={userInfo.firstMajor}
          required
        >
          <option value="" disabled hidden>
            학과를 선택해주세요.
          </option>
          {userInfo.firstMajorCol &&
            majorList.iS[userInfo.firstMajorCol].map((dept, idx) => (
              <option value={dept.deptNm} key={idx}>
                {dept.deptNm}
              </option>
            ))}
        </S.SignupBigSelect>
      </S.SignupGroupWrapper>
      <S.SignupGroupWrapper>
        <S.Signuplabel htmlFor="majorType">제2전공</S.Signuplabel>
        <S.SignupDownIcon />
        <S.SignupBigSelect
          id="majorType"
          onChange={handleUserInfo}
          value={userInfo.majorType}
          required
        >
          <option value="" hidden>
            제2전공을 선택해주세요.
          </option>
          <option value="IS" key={0}>
            심화전공(제2전공 없음)
          </option>
          <option value="DM" key={1}>
            이중전공
          </option>
          <option value="DD" key={2}>
            복수전공
          </option>
          <option value="ID" key={3}>
            융합전공
          </option>
          <option value="SD" key={4}>
            학생설계전공
          </option>
        </S.SignupBigSelect>
      </S.SignupGroupWrapper>
      {userInfo.majorType === 'IS' && (
        <>
          <S.SignupGroupWrapper>
            <S.Signuplabel htmlFor="secondMajorCol">
              제2전공 대학/학부
            </S.Signuplabel>
            <S.SignupDownIcon />
            <S.SignupBigSelect
              id="secondMajorCol"
              onChange={handleUserInfo}
              value={userInfo.secondMajorCol}
              required
            >
              <option value="" disabled hidden>
                대학/학부를 선택해주세요.
              </option>
              <option value={userInfo.firstMajorCol} hidden>
                {userInfo.firstMajorCol}
              </option>
            </S.SignupBigSelect>
          </S.SignupGroupWrapper>
          <S.SignupGroupWrapper>
            <S.Signuplabel htmlFor="secondMajor">제2전공 학과</S.Signuplabel>
            <S.SignupDownIcon />
            <S.SignupBigSelect
              id="secondMajor"
              onChange={handleUserInfo}
              value={userInfo.secondMajor}
              required
            >
              <option value="" disabled hidden>
                학과를 선택해주세요.
              </option>
              <option value={userInfo.firstMajor} hidden>
                {userInfo.firstMajor}
              </option>
            </S.SignupBigSelect>
          </S.SignupGroupWrapper>
        </>
      )}
      {(userInfo.majorType === 'DM' || userInfo.majorType === 'DD') && (
        <>
          <S.SignupGroupWrapper>
            <S.Signuplabel htmlFor="secondMajorCol">
              제2전공 대학/학부
            </S.Signuplabel>
            <S.SignupDownIcon />
            <S.SignupBigSelect
              id="secondMajorCol"
              onChange={handleUserInfo}
              value={userInfo.secondMajorCol}
              required
            >
              <option value="" disabled hidden>
                대학/학부를 선택해주세요.
              </option>
              {Object.keys(majorList.iS).map((col, idx) => (
                <option value={col} key={idx}>
                  {col}
                </option>
              ))}
            </S.SignupBigSelect>
          </S.SignupGroupWrapper>
          <S.SignupGroupWrapper>
            <S.Signuplabel htmlFor="secondMajor">제2전공 학과</S.Signuplabel>
            <S.SignupDownIcon />
            <S.SignupBigSelect
              id="secondMajor"
              onChange={handleUserInfo}
              value={userInfo.secondMajor}
              required
            >
              <option value="" disabled hidden>
                학과를 선택해주세요.
              </option>
              {userInfo.secondMajorCol &&
                majorList.iS[userInfo.secondMajorCol].map((dept, idx) => (
                  <option value={dept.deptNm} key={idx}>
                    {dept.deptNm}
                  </option>
                ))}
            </S.SignupBigSelect>
          </S.SignupGroupWrapper>
        </>
      )}
      {(userInfo.majorType === 'ID' || userInfo.majorType === 'SD') && (
        <S.SignupGroupWrapper>
          <S.Signuplabel htmlFor="secondMajor">제2전공 학과</S.Signuplabel>
          <S.SignupDownIcon />
          <S.SignupBigSelect
            id="secondMajor"
            onChange={handleUserInfo}
            value={userInfo.secondMajor}
            required
          >
            <option value="" disabled hidden>
              학과를 선택해주세요.
            </option>
            {userInfo.majorType === 'ID' &&
              majorList.iD.map((dept, idx) => (
                <option value={dept.deptNm} key={idx}>
                  {dept.deptNm}
                </option>
              ))}
            {userInfo.majorType === 'SD' &&
              majorList.sD.map((dept, idx) => (
                <option value={dept.deptNm} key={idx}>
                  {dept.deptNm}
                </option>
              ))}
          </S.SignupBigSelect>
        </S.SignupGroupWrapper>
      )}
      <S.SignupBtn type="submit">
        {loc === 'signup' ? <span>완료</span> : <span>저장하기</span>}
      </S.SignupBtn>
    </S.Signupform>
  );
};

export default PSignupContainer;
