import { useEffect } from 'react';
import { hasToken } from './index';

// 컴포넌트에서 useState를 이용해서 loggedIn 상태를 만들고, setState 함수를 넘겨주면 로그인 상태가 세팅된다.
export const useLoggedIn = (setIsLoggedIn) => {
  const isLoggedIn = hasToken();
  return useEffect(() => {
    setIsLoggedIn(isLoggedIn);
  }, []);
};
