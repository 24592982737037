import { useHistory } from 'react-router-dom';

import * as S from './style';

const ErrorPage = () => {
  const history = useHistory();
  return (
    <S.Wrapper>
      <S.ErrorText>404</S.ErrorText>
      <S.ErrorSubText>
        주소를 찾지 못하였습니다. 다시 시도해주세요.
      </S.ErrorSubText>
      <S.GoMainBtn onClick={() => history.replace('/')}>
        홈으로 돌아가기
      </S.GoMainBtn>
    </S.Wrapper>
  );
};
export default ErrorPage;
