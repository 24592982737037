import styled from 'styled-components';
import Slider from 'react-slick';
import { AiOutlineEye } from 'react-icons/ai';
import { IoShareSocialOutline } from 'react-icons/io5';

export const FdCarouselWrapper = styled.div`
  width: -webkit-fill-available;
  margin: 2rem 3rem 2.5rem 3rem;
  display: flex;
  flex-direction: column;
`;
export const FdSlider = styled(Slider)`
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #484848;
  }
  .slick-dots li button:before {
    pointer-events: none;
    opacity: 1;
    color: #cfcfcf;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #cfcfcf;
  }
  @media screen and (max-width: 345px) {
    .slick-dots li {
      width: 10px;
      height: 10px;
    }
  }
  .slick-slide {
    display: flex;
    div {
      align-self: center;
    }
  }
`;

export const FdPostPageContainer = styled.div`
  padding-bottom: 1.5rem;
`;

export const FdPostWrapper = styled.div`
  display: flex;
  width: 85%;
  min-height: auto;
  margin: 20px auto auto auto;
  flex-direction: column;
  margin-bottom: 115px;
`;

export const FdDate = styled.div`
  font-size: 0.8rem;
  color: #a0a0a0;
  @media screen and (max-width: 345px) {
    padding: 0 0.8rem;
    font-size: 0.8rem;
  }
`;

export const FdTitle = styled.div`
  text-align: left;
  padding: 1rem 0;
  font-weight: 700;
  font-size: 1.25rem;
  @media screen and (max-width: 345px) {
    padding: 0.8rem;
    font-size: 1rem;
  }
`;
export const FdContent = styled.div`
  padding: 1rem 0;
  word-break: keep-all;
  line-height: 150%;
  @media screen and (max-width: 345px) {
    padding: 0 0.8rem 0.8rem;
    font-size: 0.8rem;
  }
  pre {
    white-space: pre-line;
  }
  border-bottom: 1.5px solid #cfcfcf;
`;
export const FdFeatureBox = styled.div`
  font-size: 1.1rem;
  line-height: 1.2;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FdLikeViewBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
  font-size: 1rem;
  svg {
    margin: 0 0.3rem;
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 345px) {
    font-size: 0.8rem;
    svg {
      font-size: 1.1rem;
    }
  }
`;

export const FdLike = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FdView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FdViewIcon = styled(AiOutlineEye)`
  color: #a0a0a0;
`;

export const FdShareIcon = styled(IoShareSocialOutline)`
  cursor: pointer;
  font-size: 1.3rem;
  color: #a0a0a0;
  @media only screen and (max-width: 345px) {
    font-size: 1.1rem;
  }
`;

export const FdShareTextarea = styled.textarea`
  z-index: -2;
  display: none;
`;

export const FdFeatText = styled.div`
  width: 25px;
  height: 25px;
  padding: 2px;
  color: #a0a0a0;
`;

export const FdLoginMsgBox = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 60px;
`;

export const LikeButtonContainer = styled.div`
  cursor: pointer;
  div {
    width: 30px;
    height: 30px;
  }
`;
