import React, { useMemo } from 'react';

import * as S from './style';
import { useLocationAssign } from '../../../utils';
import { ASK_URL, FAQ_URL, NOTION_URL } from '../../../constants';

const LoggedInMenu = ({ logoutHandler, signoutHandler }) => {
  return (
    <S.MenuContainer>
      <S.MenuList>
        <S.MenuEl href={NOTION_URL} target="_blank" rel="noopener noreferrer">
          서비스 소개
        </S.MenuEl>
        <S.MenuEl href={ASK_URL} target="_blank" rel="noopener noreferrer">
          문의하기
        </S.MenuEl>
        <S.MenuEl href={FAQ_URL} target="_blank" rel="noopener noreferrer">
          FAQ
        </S.MenuEl>
        <S.MenuEl className="logout" onClick={logoutHandler}>
          로그아웃
        </S.MenuEl>
        <S.MenuEl
          className="signout"
          onClick={signoutHandler}
          style={useMemo(() => ({ color: '#A0A0A0' }))}
        >
          회원탈퇴
        </S.MenuEl>
      </S.MenuList>
    </S.MenuContainer>
  );
};

export default LoggedInMenu;
