import React, { useState } from 'react';

// eslint-disable-next-line import/named
import { LoggedInMyPage, LoggedOutMyPage } from '../../components/MyPage';
import Footer from '../../components/Common/Footer';
import { useLoggedIn } from '../../utils';

const MyPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useLoggedIn(setIsLoggedIn);

  return (
    <>
      {isLoggedIn ? <LoggedInMyPage /> : <LoggedOutMyPage />}
      <Footer />
    </>
  );
};

export default MyPage;
