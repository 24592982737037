const clasList = {
  '00': {
    courNm: '전공',
    cols: {
      '0231': {
        colNm: '간호대학',
        data: { '0233': { id: 1, deptNm: '간호학과' } },
      },
      '0140': {
        colNm: '경영대학',
        data: { '0142': { id: 2, deptNm: '경영학과' } },
      },
      '0217': {
        colNm: '공과대학',
        data: {
          4065: { id: 3, deptNm: '공과대학' },
          4952: { id: 4, deptNm: '기계공학부' },
          5320: { id: 5, deptNm: '산업경영공학부' },
          4630: { id: 6, deptNm: '신소재공학부' },
          5597: { id: 7, deptNm: '전기전자공학부' },
          5204: { id: 8, deptNm: '건축사회환경공학부' },
          4887: { id: 9, deptNm: '건축학과' },
          6544: { id: 10, deptNm: '기술창업융합전공' },
          6723: { id: 11, deptNm: '반도체공학과' },
          7055: { id: 12, deptNm: '에너지신산업융합전공' },
          6724: { id: 13, deptNm: '융합에너지공학과' },
          4084: { id: 14, deptNm: '화공생명공학과' },
        },
      },
      6909: {
        colNm: '국제대학',
        data: {
          6910: { id: 15, deptNm: '국제학부' },
          7037: { id: 16, deptNm: 'GKS 융합전공' },
          6911: { id: 133, deptNm: '글로벌한국융합학부' },
        },
      },
      5338: {
        colNm: '디자인조형학부',
        data: { 5339: { id: 17, deptNm: '디자인조형학부' } },
      },
      '0143': {
        colNm: '문과대학',
        data: {
          4067: { id: 18, deptNm: '문과대학' },
          5672: { id: 19, deptNm: 'EML융합전공' },
          6095: { id: 20, deptNm: 'GLEAC융합전공' },
          6093: { id: 21, deptNm: 'LB&C융합전공' },
          4601: { id: 22, deptNm: '과학기술학융합전공' },
          '0145': { id: 23, deptNm: '국어국문학과' },
          '0156': { id: 24, deptNm: '노어노문학과' },
          '0153': { id: 25, deptNm: '독어독문학과' },
          '0154': { id: 26, deptNm: '불어불문학과' },
          '0803': { id: 27, deptNm: '사학과' },
          '0152': { id: 28, deptNm: '사회학과' },
          '0158': { id: 29, deptNm: '서어서문학과' },
          4391: { id: 30, deptNm: '언어학과' },
          '0146': { id: 31, deptNm: '영어영문학과' },
          6342: { id: 32, deptNm: '의료인문학융합전공' },
          6094: { id: 33, deptNm: '인문학과문화산업융합전공' },
          5539: { id: 34, deptNm: '인문학과정의융합전공' },
          '0157': { id: 35, deptNm: '일어일문학과' },
          '0155': { id: 36, deptNm: '중어중문학과' },
          '0147': { id: 37, deptNm: '철학과' },
          6463: { id: 38, deptNm: '통일과국제평화융합전공' },
          '0148': { id: 39, deptNm: '한국사학과' },
          '0159': { id: 40, deptNm: '한문학과' },
        },
      },
      5256: {
        colNm: '미디어학부',
        data: { 5257: { id: 41, deptNm: '미디어학부' } },
      },
      4669: {
        colNm: '보건과학대학',
        data: {
          5694: { id: 42, deptNm: '바이오시스템의과학부' },
          5693: { id: 43, deptNm: '바이오의공학부' },
          5696: { id: 44, deptNm: '보건정책관리학부' },
          5695: { id: 45, deptNm: '보건환경융합과학부' },
          4672: { id: 129, deptNm: '물리치료학과' },
        },
      },
      '0234': {
        colNm: '사범대학',
        data: {
          '0238': { id: 46, deptNm: '가정교육과' },
          '0236': { id: 47, deptNm: '교육학과' },
          '0240': { id: 48, deptNm: '국어교육과' },
          5753: { id: 49, deptNm: '다문화한국어교육융합전공' },
          '0239': { id: 50, deptNm: '수학교육과' },
          '0243': { id: 51, deptNm: '역사교육과' },
          '0241': { id: 52, deptNm: '영어교육과' },
          '0242': { id: 53, deptNm: '지리교육과' },
          '0237': { id: 54, deptNm: '체육교육과' },
          4638: { id: 55, deptNm: '패션디자인및머천다이징융합전공' },
        },
      },
      4652: {
        colNm: '생명과학대학',
        data: {
          4654: { id: 56, deptNm: '생명공학부' },
          4719: { id: 57, deptNm: '생명과학대학' },
          4653: { id: 58, deptNm: '생명과학부' },
          4656: { id: 59, deptNm: '환경생태공학부' },
          5186: { id: 60, deptNm: '기후변화융합전공' },
          6607: { id: 61, deptNm: '미생물융합기술융합전공' },
          4425: { id: 62, deptNm: '생태조경융합전공' },
          5564: { id: 63, deptNm: '식품공학과' },
          4657: { id: 64, deptNm: '식품자원경제학과' },
          5019: { id: 130, deptNm: '의과학융합전공' },
        },
      },
      6726: {
        colNm: '스마트보안학부',
        data: {
          6880: { id: 65, deptNm: '사이버국방학과' },
          6727: { id: 66, deptNm: '스마트보안학부' },
        },
      },
      6564: {
        colNm: '심리학부',
        data: { 6565: { id: 67, deptNm: '심리학부' } },
      },
      '0226': {
        colNm: '의과대학',
        data: {
          '0228': { id: 68, deptNm: '의예과' },
          '0229': { id: 69, deptNm: '의학과' },
        },
      },
      '0209': {
        colNm: '이과대학',
        data: {
          4063: { id: 70, deptNm: '이과대학' },
          '0212': { id: 71, deptNm: '물리학과' },
          '0211': { id: 72, deptNm: '수학과' },
          '0215': { id: 73, deptNm: '지구환경과학과' },
          '0213': { id: 74, deptNm: '화학과' },
        },
      },
      '0197': {
        colNm: '정경대학',
        data: {
          '0200': { id: 75, deptNm: '경제학과' },
          5046: { id: 76, deptNm: '금융공학융합전공' },
          '0199': { id: 77, deptNm: '정치외교학과' },
          '0201': { id: 78, deptNm: '통계학과' },
          '0203': { id: 79, deptNm: '행정학과' },
        },
      },
      5720: {
        colNm: '정보대학',
        data: {
          6666: { id: 80, deptNm: '뇌인지과학융합전공' },
          5965: { id: 81, deptNm: '소프트웨어벤처융합전공' },
          6545: { id: 82, deptNm: '인공지능융합전공' },
          5944: { id: 83, deptNm: '정보보호 융합전공' },
          5722: { id: 84, deptNm: '컴퓨터학과' },
          6725: { id: 134, deptNm: '데이터과학과' },
        },
      },
      5961: {
        colNm: 'KU-KIST융합대학원(관)',
        data: { 5962: { id: 85, deptNm: '메디컬융합공학융합전공 ' } },
      },
      6458: {
        colNm: '법학전문대학원(관)',
        data: { 6459: { id: 86, deptNm: '법학전문대학원' } },
      },
      5963: {
        colNm: '정보보호대학원(관)',
        data: { 5964: { id: 87, deptNm: '융합보안융합전공 ' } },
      },
      7157: {
        colNm: '현장실습지원센터(관)',
        data: { 7158: { id: 135, deptNm: '현장실습지원센터' } },
      },
    },
  },
  24: {
    courNm: '학문의기초',
    cols: {
      '0231': {
        colNm: '간호대학',
        data: { '0233': { id: 88, deptNm: '간호학과' } },
      },
      '0140': {
        colNm: '경영대학',
        data: { '0142': { id: 89, deptNm: '경영학과' } },
      },
      '0217': {
        colNm: '공과대학',
        data: {
          4065: { id: 90, deptNm: '공과대학' },
          6724: { id: 91, deptNm: '융합에너지공학과' },
        },
      },
      5338: {
        colNm: '디자인조형학부',
        data: { 5339: { id: 92, deptNm: '디자인조형학부' } },
      },
      '0143': {
        colNm: '문과대학',
        data: {
          4067: { id: 93, deptNm: '문과대학' },
          '0156': { id: 94, deptNm: '노어노문학과' },
          '0153': { id: 95, deptNm: '독어독문학과' },
          '0154': { id: 96, deptNm: '불어불문학과' },
          '0158': { id: 97, deptNm: '서어서문학과' },
          '0146': { id: 98, deptNm: '영어영문학과' },
          '0157': { id: 99, deptNm: '일어일문학과' },
          '0155': { id: 100, deptNm: '중어중문학과' },
        },
      },
      4669: {
        colNm: '보건과학대학',
        data: { 5696: { id: 101, deptNm: '보건정책관리학부' } },
      },
      4652: {
        colNm: '생명과학대학',
        data: { 4657: { id: 102, deptNm: '식품자원경제학과' } },
      },
      '0209': {
        colNm: '이과대학',
        data: { '0211': { id: 103, deptNm: '수학과' } },
      },
      '0197': {
        colNm: '정경대학',
        data: {
          '0201': { id: 104, deptNm: '통계학과' },
          '0203': { id: 105, deptNm: '행정학과' },
        },
      },
      5720: {
        colNm: '정보대학',
        data: { 5722: { id: 106, deptNm: '컴퓨터학과' } },
      },
      6726: {
        colNm: '스마트보안학부',
        data: { 6727: { id: 131, deptNm: '스마트보안학부' } },
      },
    },
  },
  '01': {
    courNm: '교양',
    data: {
      24: { id: 107, colNm: '1학년세미나' },
      23: { id: 108, colNm: 'ACADEMIC ENGLISH' },
      47: { id: 109, colNm: 'CB프로그램' },
      16: { id: 110, colNm: '과학과기술' },
      10: { id: 111, colNm: '군사학' },
      44: { id: 112, colNm: '글쓰기' },
      18: { id: 113, colNm: '디지털혁신과인간' },
      13: { id: 114, colNm: '문학과예술' },
      15: { id: 115, colNm: '사회의이해' },
      32: { id: 116, colNm: '선택교양' },
      46: { id: 117, colNm: '선택교양(기초과학)' },
      45: { id: 118, colNm: '선택교양(외국어)' },
      11: { id: 119, colNm: '세계의문화' },
      12: { id: 120, colNm: '역사의탐구' },
      14: { id: 121, colNm: '윤리와사상' },
      43: { id: 122, colNm: '자유정의진리' },
      17: { id: 123, colNm: '정량적사고' },
      42: { id: 132, colNm: '정보적사고' },
      49: { id: 136, colNm: 'DS/AI' },
      48: { id: 137, colNm: 'GLOBAL ENGLISH' },
    },
  },
  30: { courNm: '교직', id: 124 },
  41: { courNm: '군사학', id: 125 },
  71: { courNm: '평생교육사', id: 126 },
};
export default clasList;
