import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineExperiment } from 'react-icons/ai';

import * as S from './style';
import AdvancedSearchModal from '../Modal/AdvancedSearchModal';

const GoExp = () => {
  const [modalSwitch, setModalSwitch] = useState(false);
  const onClick = () => {
    setModalSwitch(true);
  };

  return (
    <S.ExpBox>
      <S.CheckContentBox>
        <S.TryExpBox>
          <b>NEW!</b>
          <p>
            전공별, 교양별로 검색하여 <br />
            <span className="bold">인기 과목</span>을 확인해보세요{' '}
          </p>
        </S.TryExpBox>
        <S.TryLogin className="advancedSearchOpen" onClick={onClick}>
          <p>상세 검색하기</p>
        </S.TryLogin>
      </S.CheckContentBox>
      <S.CheckImage>
        <AiOutlineExperiment />
      </S.CheckImage>
      {modalSwitch && <AdvancedSearchModal setModalSwitch={setModalSwitch} />}
    </S.ExpBox>
  );
};

export default GoExp;
