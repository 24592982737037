import styled from 'styled-components';

export const PuBackdrop = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
`;

export const PuContainer = styled.div`
  width: 320px;
  // height: 420px;
  position: fixed;
  top: 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 150px;
  background: white;
  box-shadow: 0 5px 14px 0 rgb(0 0 0 / 19%);
  border-radius: 3px;
  word-break: keep-all;
  pre {
    text-align: start;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 15px;
    white-space: pre-line;
    word-break: keep-all;
  }
  button {
    color: #707070;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }
  .checkIcon {
    font-size: 13px;
  }

  @media screen and (max-width: 350px) {
    width: 300px;
    // height: 400px;
  }
`;

export const PuHeader = styled.div`
  width: 100%;
  display: flex;
  place-content: flex-start;
  p {
    padding-left: 20px;
    font-size: 21px;
    font-weight: 800;
    color: #c02e2e;
    padding-top: 1px;
    padding-bottom: 6px;
    @media screen and (max-width: 350px) {
      font-size: 18px;
    }
  }
`;

export const PuMsgBox = styled.div`
  width: 100%;
  height: 66%;
  padding: 14px 20px 0px 20px;
  font-size: 13.6px;
  ul {
    padding-left: 13px;
    li {
      margin-bottom: 2px;
    }
  }
  p {
    // color: #707070;
    line-height: 1.3;
    word-break: keep-all;
    margin-bottom: 4px;
  }
  .context {
    margin-bottom: 15px;
  }
  .subtext {
    margin-bottom: 6px;
  }
  .subtitle {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 6px;
    a {
      // color: black;
      text-decoration: underline;
    }
  }
  .bold {
    font-weight: 700;
  }
  .sublink {
    text-align: end;
    font-size: 14px;
  }
`;
export const PuLinkBox = styled.div`
  height: 15%;
  width: 90%;
  margin-top: 15px;
  // border-top: 0.5px #a0a0a0 solid;
  align-self: flex-start;
  display: flex;
  flex-flow: column;
  place-self: center;
  display: flex;
  flex-flow: row;
  place-content: space-between;
  place-items: center;
  p {
    font-size: 13px;
    span {
      font-weight: 700;
    }
  }
  .highlight {
    line-height: 1.2;
    font-weight: 600;
    font-size: 15px;
  }
  span {
    color: lightcoral;
  }
  a {
    padding-top: 7px;
    color: #c02e2e;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const PuFooter = styled.div`
  width: 100%;
  height: 13%;
  border-top: 1px solid #bdbdbd;
  text-align: end;
  button {
    width: 50%;
    height: 41px;
    color: #a0a0a0;
    font-size: 14px;
    cursor: pointer;
  }
  .leftBox {
    border-right: 1px solid #bdbdbd;
  }
`;
