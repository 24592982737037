import React from 'react';
import { Link } from 'react-router-dom';
import { RiCalendar2Line } from 'react-icons/ri';
import { IoPersonSharp } from 'react-icons/io5';
import { CalendarModal } from '../../Modal';
import { SearchBar } from '../../Search';
import * as S from './style';
import { REG, YEAR, TERM } from '../../../constants/DEFAULT_VALUE';

const PMainHeader = ({ text, setText, userInfoSum, periodList }) => {
  const LOGO_URL =
    'https://ku-sugang.s3.ap-northeast-2.amazonaws.com/logo/2023-white-logo.png';
  const [modalSwitch, setModalSwitch] = React.useState(false);
  const onClick = () => {
    setModalSwitch(true);
  };

  return (
    <S.Wrapper>
      <S.MhContainer>
        <S.MhTopHeader>
          <S.MhLogoBox>
            <img src={LOGO_URL} alt="main logo" />
          </S.MhLogoBox>
          {userInfoSum.grade === null ? (
            <S.MhMyBox>
              <Link to="/account/login/">
                <span>로그인하기</span>
              </Link>
            </S.MhMyBox>
          ) : (
            <S.MhMyBox>
              <img
                src="https://ku-sugang.s3.ap-northeast-2.amazonaws.com/icon/my.svg"
                alt="mypage"
              />
              <Link to="/account/mypage/">
                <span>
                  {userInfoSum.grade}학년, {userInfoSum.nickname}님
                </span>
              </Link>
            </S.MhMyBox>
          )}
        </S.MhTopHeader>
        <S.MhPeriod>
          <S.MhPeriodHeader>
            <S.PeriodText>
              <p>{periodList.date}</p>
              <p>{periodList.msg}</p>
            </S.PeriodText>
            <S.MhCalender className="calendar" onClick={onClick}>
              <RiCalendar2Line />
            </S.MhCalender>
          </S.MhPeriodHeader>
          <S.MhSubText>{periodList.time}</S.MhSubText>
        </S.MhPeriod>
        <S.SearchBar>
          <SearchBar
            text={text}
            period={`${YEAR + TERM}`}
            reg={REG}
            setText={setText}
          />
        </S.SearchBar>
      </S.MhContainer>
      {modalSwitch && (
        <CalendarModal
          grade={userInfoSum.grade}
          setModalSwitch={setModalSwitch}
        />
      )}
    </S.Wrapper>
  );
};
export default React.memo(PMainHeader);
