import styled from 'styled-components';

export const FlhContainer = styled.div`
  margin-bottom: -5px;
`;

export const FlhTop = styled.div`
  display: flex;
  width: 90%;
  margin: 1.25rem auto;
  div {
    margin-top: 10px;
    color: black;
    font-size: 2rem;
  }
`;
export const FlhName = styled.div`
  display: flex;
  width: 85%;
  margin: 0 auto;
  padding-bottom: 0.75rem;
  border-bottom: 1.5px solid #00000054;
`;

export const FlsContainer = styled.div`
  width: 85%;
  margin: 20px auto 0 auto;
  padding-bottom: 90px;
`;
export const FlsWrapper = styled.div`
  animation: fadeIn 0.7s ease;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(15px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  color: #767676;
  border-radius: 10px;
  margin: 36px 0;
  padding: 22px 32px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
export const FlsHeader = styled.div`
  display: flex;
  place-content: space-between;
  margin-top: 7px;
  border-bottom: 1px solid #80808042;
  padding-bottom: 5px;
`;
export const FlsDtBox = styled.div`
  line-height: 0px;
  margin-top: 10px;
  color: #767676;
  font-size: 0.9rem;
`;
export const FlsSubInfoBox = styled.div`
  width: 100%;
  line-height: 1.3;
  display: inline-flex;
  justify-content: flex-end;
  color: #a0a0a0;
  p {
    font-size: 0.8rem;
    margin: 0 3px;
  }
  svg {
    font-size: 1.1rem;
    margin-left: 7px;
  }
`;
export const FlsSection = styled.div``;
export const FlsTitleBox = styled.div`
  word-break: keep-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: black;
  font-size: 1.13rem;
  margin: 8px 0;
`;
export const FlsContentBox = styled.div`
  max-height: 47px;
  overflow: hidden;
  word-break: keep-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 8px 0;
`;
export const FlsStoryWrapper = styled.div`
  animation: fadeIn 0.7s ease;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(15px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  border-bottom: #cfcfcf solid 1px;
  place-content: space-between;
  width: 100%;
  display: flex;
  height: 190px;
  color: #767676;
  padding: 10px;
  @media only screen and (max-width: 400px) {
    height: 150px;
    margin: initial;
  }
  @media only screen and (max-width: 325px) {
    height: 130px;
  }
`;
export const FlsStoryHeader = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  background: url(${(props) => props.imgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 9px;
`;
export const FlsStorySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  width: 50%;
  /* @media only screen and (max-width: 400px) {
    margin: 10px 0 20px 20px;
  } */
`;
export const FlsStoryTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: black;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 10px;
`;
export const FlsImgBox = styled.div`
  img {
    width: 100%;
  }
`;
export const FlsSubBox = styled.div`
  display: flex;
  flex-direction: column;
  place-content: space-between;
  margin: 5px 0;
`;
