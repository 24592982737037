import styled from 'styled-components';

export const Wrapper = styled.div`
  width: -webkit-fill-available;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MhContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 250px;
  padding: 0px 20px 5px 20px;
  background-color: #c02e2e;
  @media only screen and (max-width: 345px) {
    padding: 0px 15px 0px 15px;
    height: 248px;
  }
`;
export const MhTopHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MhLogoBox = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  img {
    @media only screen and (max-width: 345px) {
      height: 25px;
    }
    height: 28px;
  }
`;
export const MhMyBox = styled.div`
  width: 173px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  a {
    color: white;
    height: 28px;
  }
  span {
    cursor: pointer;
    line-height: 28px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 2px;
    @media only screen and (max-width: 345px) {
      font-size: 0.65rem;
    }
  }
  img {
    width: 16px;
    @media only screen and (max-width: 345px) {
      width: 13px;
    }
  }
`;

export const MhPeriod = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: webkit-fill-available;
`;

export const MhPeriodHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const PeriodText = styled.div`
  margin-bottom: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 100%;
  color: #ffffff;
  & > P {
    margin-top: 10px;
  }
  @media only screen and (max-width: 345px) {
    font-size: 1.1rem;
  }
`;
export const MhSubText = styled.div`
  border-top: 1px solid white;
  margin-top: 10px;
  padding-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #ffd0d7;
  @media only screen and (max-width: 345px) {
    font-size: 12px;
  }
`;
export const SearchBar = styled.div`
  & > :first-child {
    padding: 0 10px;
    height: 46px;
    background: #ffffff;
    border-radius: 10px;
    @media only screen and (max-width: 345px) {
      padding: 0 7px;
    }
  }
  svg {
    font-size: 20px;
  }
`;
export const MhCalender = styled.div`
  cursor: pointer;
  display: flex;
  svg {
    color: white;
    font-size: 1.4rem;
  }
`;
