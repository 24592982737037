import axios from 'axios';
import { setInterceptors } from './interceptors';
import { withoutInterceptors } from './withoutInterceptors';

const defaultURL =
  process.env.REACT_APP_API_URL || 'https://server.ku-sugang.com/';
function withoutAuth() {
  const instance = axios.create({
    baseURL: defaultURL,
  });
  return withoutInterceptors(instance);
}

function withAuth() {
  const instance = axios.create({
    baseURL: defaultURL,
  });

  return setInterceptors(instance);
}

export const withoutAuthInstance = withoutAuth();
export const withAuthInstance = withAuth();
