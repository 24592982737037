import React from 'react';
import { Link } from 'react-router-dom';
import { BiLockOpenAlt } from 'react-icons/bi';

import * as S from './style';

const GoLoginPage = (loc) => {
  let text = '';
  if (loc === 'search') {
    text = `로그인 후 현재 수강희망과목등록 정보 및 
    과거 수강희망/수강신청 과목 정보들을 확인해보세요.`;
  } else {
    text = `로그인 후 원하는 과목들의 정보를 
  한 눈에 확인하고 빈자리 알림까지 받아보세요.`;
  }
  return (
    <S.GlpCheckLoginContainer>
      <div>
        <S.GlpLockIcon>
          <BiLockOpenAlt />
        </S.GlpLockIcon>
        <pre>{text}</pre>
        <S.GlpCheckLoginButton>
          <Link className="login" to="/account/login/">
            <p>로그인하기</p>
          </Link>
        </S.GlpCheckLoginButton>
      </div>
    </S.GlpCheckLoginContainer>
  );
};

export default GoLoginPage;
