import React, { useState, useEffect } from 'react';
import { getClassPeriodApi, getUserInfoSumApi } from '../../../utils';
import PMainHeader from './PMainHeader';

const MainHeader = () => {
  const [text, setText] = useState('');
  const [periodList, setPeriodList] = useState({});
  const [userInfoSum, setUserInfoSum] = useState({ grade: null, nickname: '' });

  const getGrade = async () => {
    const [data, error] = await getUserInfoSumApi();
    if (error) {
      // alert('에러가 발생했습니다. 다시 로그인 해주세요.');
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      window.location.href = `/account/login/`;
    }
    if (data.grade) {
      setUserInfoSum({ ...data });
    }
    getPeriod(data.grade);
  };

  const getPeriod = async (userGrade) => {
    const res = await getClassPeriodApi();
    if (res[0]) {
      const { state } = res[0];
      const copy = { ...periodList };
      copy.msg = res[0].msg;
      if (state === 'OP') {
        copy.time = '7.22(월) 10:00 - 8.2(금) 13:00';
        copy.date = '7월 22일 - 8월 2일은';
      } else if (state === 'RP') {
        copy.time = '8.2(금) 13:00 - 8.5(월) 12:00';
        copy.date = '8월 2일 - 5일은';
      } else if (state === 'IP') {
        copy.time = '8.9(금) 10:00 - 8.12(월) 08:30';
        copy.date = '8월 9일 - 12일은';
      } else if (state === 'LP') {
        copy.time = '8.12(월) 10:00 - 8.13(화) 09:00';
        copy.date = '8월 12일 - 13일은';
      } else if (state === 'JP') {
        copy.time = '8.13(화) 10:00 - 8.14(수) 09:00';
        copy.date = '8월 13일 - 14일은';
      } else if (state === 'SP') {
        copy.time = '8.14(수) 10:00 - 8.15(목) 09:00';
        copy.date = '8월 14일 - 15일은';
      } else if (state === 'FP') {
        copy.time = '8.16(금) 10:00 - 8.17(토) 09:00';
        copy.date = '8월 16일 - 17일은';
      } else if (state === 'TP') {
        copy.time = '8.21(수) 10:00 - 8.22(목) 12:00';
        copy.date = '8월 21일 - 22일은';
      } else if (
        state === 'GL' ||
        state === 'GJ' ||
        state === 'GS' ||
        state === 'GF'
      ) {
        // eslint-disable-next-line prefer-destructuring
        copy.msg = res[0].msg.split(')')[1];
        if (userGrade === 1) {
          copy.time = '[1학년]  9.4(수) 21:30 - 9.5(목) 12:00';
        } else if (userGrade === 2) {
          copy.time = '[2학년]  9.4(수) 20:30 - 9.5(목) 12:00';
        } else if (userGrade === 3) {
          copy.time = '[3학년]  9.4(수) 19:30 - 9.5(목) 12:00';
        } else if (userGrade === 4) {
          copy.time = '[4학년]  9.4(수) 18:30 - 9.5(목) 12:00';
        } else {
          copy.time = '[4학년]  9.4(수) 18:30 - 9.5(목) 12:00';
        }
        copy.date = '9월 4일 - 5일은';
      } else if (state === 'AP') {
        copy.time = '9.5(목) 18:30 - 9.6(금) 12:00';
        copy.date = '9월 5일 - 7일은';
      } else {
        copy.time = '달력을 누르면 전체 일정을 확인할 수 있습니다.';
        copy.date = '지금은';
      }
      setPeriodList(copy);
    }
  };

  useEffect(() => {
    getGrade();
  }, []);

  return (
    <PMainHeader
      text={text}
      setText={setText}
      userInfoSum={userInfoSum}
      periodList={periodList}
    />
  );
};

export default React.memo(MainHeader);
