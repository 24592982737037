import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowUpRight } from 'react-icons/fi';
import { RiBookMarkLine } from 'react-icons/ri';
import Filter from '../../Common/Filter';
import * as S from '../MainContents/style';
import { CourseCard } from '../../Search';

const PMainFavorites = ({ favInfo, isFavorite, changeURL, handleP }) => {
  return (
    <S.SugangContents>
      <S.BoardTitle>
        <div>
          즐겨찾기한 과목
          <S.MfRedText>{favInfo.myMarksCount}</S.MfRedText>
          <S.MfDarkGrayText>/10개</S.MfDarkGrayText>
        </div>
        <Link to={changeURL}>
          <FiArrowUpRight fontSize="30px" color="black" />
        </Link>
      </S.BoardTitle>

      <S.BoardText>
        {favInfo.myMarksCount === 0 ? (
          <span>원하는 과목을 즐겨찾기 해보세요</span>
        ) : (
          <span>스크롤하여 여러 과목을 확인해보세요</span>
        )}
        <S.FillterImage>
          <Filter
            selectValue1="prereg"
            selectValue2="register"
            selectText1="수강희망"
            selectText2="수강신청"
            value={isFavorite}
            handleP={handleP}
          />
        </S.FillterImage>
      </S.BoardText>
      {favInfo.myMarksCount === 0 ? (
        <S.FavoritesContainer>
          <S.ContainerBox>
            <RiBookMarkLine />
          </S.ContainerBox>
          <S.ContainerText>
            <p>즐겨찾기를 추가하면</p>
            <p>빠르게 강좌를 확인할 수 있어요</p>
          </S.ContainerText>
        </S.FavoritesContainer>
      ) : (
        <S.TotalContents>
          {favInfo &&
            favInfo.markedList.map((course) => (
              <CourseCard
                key={course.courseInfo.id}
                regStatus={isFavorite}
                course={course}
                ismain
                isPresent
              />
            ))}
        </S.TotalContents>
      )}
    </S.SugangContents>
  );
};
export default PMainFavorites;
