import { camelizeKeys, decamelizeKeys } from 'humps';

export function withoutInterceptors(instance) {
  instance.interceptors.request.use(
    (res) => {
      const newConfig = { ...res };
      newConfig.url = `${res.url}`;
      if (newConfig.headers['Content-Type'] === 'multipart/form-data')
        return newConfig;
      if (res.params) {
        newConfig.params = decamelizeKeys(res.params);
      }
      if (res.data) {
        newConfig.data = decamelizeKeys(res.data);
      }
      return newConfig;
    },
    (err) => Promise.reject(err),
  );
  instance.interceptors.response.use(
    (response) => {
      // console.dir(response.data);
      // caseConverter
      if (
        response.data &&
        response.headers['content-type'] === 'application/json'
      ) {
        response.data = camelizeKeys(response.data);
      }

      return [response.data, null];
    },
    async (error) => {
      // console.dir(error);
      return [null, error.response.data];
    },
  );
  return instance;
}
