import React, { useState, useContext, useCallback } from 'react';
import PCourseCard from './PCourseCard.jsx';
import {
  editBookmarkApi,
  editNotifApi,
  getClassDetailApi,
} from '../../../utils/class.js';
import { CourseModal, ConfirmModal } from '../../Modal';
import * as S from '../style';
import { GlobalContext } from '../../../App';

const CourseCard = ({ regStatus, course, ismain, isPresent }) => {
  const { isOpening, setIsOpening } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [courseDetail, setCourseDetail] = useState();
  const [bookmarkedNum, setBookmarkedNum] = useState(course.markedCount);
  const [isBookmarked, setIsBookmarked] = useState(course.isMarked);
  const [notiNum, setNotiNum] = useState(course.notiCount);
  const [isNotiOn, setIsNotiOn] = useState(course.isNotiOn);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const isCourseCard = true;

  const editBookmark = async (regStatus, courseId) => {
    if (localStorage.getItem('access-token')) {
      const [data, error] = await editBookmarkApi(regStatus, courseId);
      if (data) {
        if (data.state === '초과') {
          alert(data.msg);
        }
        if (!data.isMarked) {
          if (data.state === '해제') {
            setBookmarkedNum((prevNum) => prevNum - 1);
          }
          setIsNotiOn(false);
        } else if (data.state === '등록') {
          setBookmarkedNum((prevNum) => prevNum + 1);
          if (!isNotiOn) {
            setIsNotiOn(true);
          }
        }
        setIsBookmarked(data.isMarked);
      }
      if (error) console.dir(error);
    } else {
      alert('로그인 후에 이용할 수 있는 기능입니다.');
    }
  };

  const toggleNotif = async (courseId) => {
    if (localStorage.getItem('access-token')) {
      const [data, error] = await editNotifApi(courseId);
      if (data) {
        if (data.state === '초과') {
          alert(data.msg);
        }
        if (data.state === '등록') {
          if (!isBookmarked) {
            setBookmarkedNum((prevNum) => prevNum + 1);
            setIsBookmarked(true);
          }
        }
        setIsNotiOn(data.isNotiOn);
      }
      if (error) console.dir(error);
    } else {
      alert('로그인 후에 이용할 수 있는 기능입니다.');
    }
  };

  const openModal = useCallback(
    (year, term, courseId) => {
      if (!isOpen && !isOpening) {
        setIsOpen(true);
        getClassDetail(year, term, courseId);
        setIsOpening(true);
      }
    },
    [isOpen, isOpening],
  );

  const closeModal = () => {
    if (isOpen && isOpening) {
      setIsOpen(false);
      setIsOpening(false);
    }
  };

  const getClassDetail = async (year, term, courseId) => {
    const [data, error] = await getClassDetailApi(
      regStatus,
      year,
      term,
      courseId,
    );
    if (data) {
      setCourseDetail(data);
    } else {
      // eslint-disable-next-line no-console
      console.dir(error);
    }
  };

  // 북마크
  // eslint-disable-next-line no-nested-ternary
  const bookMarker = isPresent ? (
    isBookmarked ? (
      <S.CcBookMarkBox>
        <S.CcFavoritesFillIcon
          ismain={ismain}
          onClick={() => editBookmark(regStatus, course.courseInfo.id)}
        />
        <S.CcBookmarkedNumBox isBookmarked={isBookmarked}>
          {bookmarkedNum}
        </S.CcBookmarkedNumBox>
      </S.CcBookMarkBox>
    ) : (
      <S.CcBookMarkBox>
        <S.CcFavoritesIcon
          ismain={ismain}
          onClick={() => editBookmark(regStatus, course.courseInfo.id)}
        />
        <S.CcBookmarkedNumBox isBookmarked={isBookmarked}>
          {bookmarkedNum}
        </S.CcBookmarkedNumBox>
      </S.CcBookMarkBox>
    )
  ) : (
    <S.CcBookMarkBox>
      <S.CcFavoritesIcon />
      <S.CcBookmarkedNumBox>{bookmarkedNum}</S.CcBookmarkedNumBox>
    </S.CcBookMarkBox>
  );

  // 알림
  // 현재, 과거에 따라 알림 아이콘의 작동 여부 분리
  // eslint-disable-next-line no-nested-ternary
  const notif = isPresent ? (
    isNotiOn ? (
      <S.CcNotifBox>
        <S.CcNotifOnIcon
          ismain={ismain}
          onClick={() => toggleNotif(course.courseInfo.id)}
        />
        <S.CcNotifNumBox ismain={ismain} isNotiOn={isNotiOn}>
          {notiNum}
        </S.CcNotifNumBox>
      </S.CcNotifBox>
    ) : (
      <S.CcNotifBox>
        <S.CcNotifOffIcon
          ismain={ismain}
          onClick={() => toggleNotif(course.courseInfo.id)}
        />
        <S.CcNotifNumBox ismain={ismain} isNotiOn={isNotiOn}>
          {notiNum}
        </S.CcNotifNumBox>
      </S.CcNotifBox>
    )
  ) : (
    <S.CcNotifBox>
      <S.CcNotifOffIcon />
      <S.CcNotifNumBox>{notiNum}</S.CcNotifNumBox>
    </S.CcNotifBox>
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const isNotif = ismain || regStatus === 'register' ? notif : <></>;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const isBookMarker = !ismain ? bookMarker : <></>;

  // 복사 기능
  let timer;
  const onClickCourse = useCallback(
    (e) => {
      const targetText = e.target.textContent;
      copyText(targetText);
      setConfirmText(`${targetText}이(가) 복사되었습니다.`);
      setIsConfirmModalOpen(true);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setIsConfirmModalOpen(false);
      }, 1500);
    },
    [timer],
  );

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // console.log(text, '가 복사되었습니다.');
      })
      .catch(() => {
        alert('복사에 실패했습니다.');
      });
  };

  return (
    <>
      <PCourseCard
        regStatus={regStatus}
        course={course}
        openModal={openModal}
        ismain={ismain}
        isBookMarker={isBookMarker}
        isNotif={isNotif}
        onClickCourse={onClickCourse}
      />
      {isOpen && courseDetail && (
        <CourseModal
          regStatus={regStatus}
          course={courseDetail}
          closeModal={closeModal}
          isMarked={isBookmarked}
          editBookmark={editBookmark}
          isNotiOn={isNotiOn}
          handleNotiToggle={() => toggleNotif(course.courseInfo.id)}
          setCourseDetail={setCourseDetail}
        />
      )}
      {isConfirmModalOpen && confirmText && (
        <ConfirmModal text={confirmText} isCourseCard={isCourseCard} />
      )}
    </>
  );
};
export default React.memo(CourseCard);
