import React from 'react';
import { GoBack } from '../../Common';
import * as S from '../style';

const PFlHeader = ({ info }) => {
  const { category } = info;
  return (
    <S.FlhContainer>
      <S.FlhTop>
        <GoBack />
      </S.FlhTop>
      {category === 'notice' ? (
        <S.FlhName>
          <h2>공지사항</h2>
        </S.FlhName>
      ) : (
        <S.FlhName>
          <h2>수알 스토리</h2>
        </S.FlhName>
      )}
    </S.FlhContainer>
  );
};
export default PFlHeader;
