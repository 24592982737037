import React from 'react';
import { useLocationAssign } from '../../../utils';
import { ASK_URL, FAQ_URL, NOTION_URL } from '../../../constants';

import * as S from './style';

const LoggedOutMenu = () => {
  const pushToAsk = useLocationAssign(ASK_URL);
  const pushToFAQ = useLocationAssign(FAQ_URL);
  const pushToNotion = useLocationAssign(NOTION_URL);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <S.MenuContainer>
        <S.MenuList>
          <S.MenuEl href={NOTION_URL} target="_blank" rel="noopener noreferrer">
            서비스 소개
          </S.MenuEl>
          <S.MenuEl href={ASK_URL} target="_blank" rel="noopener noreferrer">
            문의하기
          </S.MenuEl>
          <S.MenuEl href={FAQ_URL} target="_blank" rel="noopener noreferrer">
            FAQ
          </S.MenuEl>
        </S.MenuList>
      </S.MenuContainer>
    </>
  );
};

export default LoggedOutMenu;
