import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import LoggedInMyPagePresenter from './presenter';
import { getUserInfoApi, patchAlarmStateApi, signoutApi } from '../../../utils';
import { Loading } from '../../Common';

const LoggedInMyPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [isNotiOn, setIsNotiOn] = useState(false);

  const history = useHistory();
  const getUserInfo = async () => {
    const [data, err] = await getUserInfoApi();
    const AUTH_ERR_CODE = 'token_not_valid';

    if (err) {
      if (err.code !== AUTH_ERR_CODE) {
        alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        history.replace('');
      }
    }
    const userData = data.user;
    setUserInfo(userData);
    setIsLoading(false);
    const { isNotiOn } = userData;
    setIsNotiOn(isNotiOn);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const notiToggleHandler = () => {
    patchAlarmStateApi();
    setIsNotiOn(!isNotiOn);
  };

  const logoutHandler = () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }
    localStorage.clear();
    history.replace('');
  };

  const signoutHandler = async () => {
    if (!window.confirm('회원탈퇴 하시겠습니까?')) {
      return;
    }
    const [data, err] = await signoutApi();
    if (err) {
      alert('에러가 발생했습니다. 다시 시도해주세요.');
      return;
    }

    localStorage.clear();

    alert('탈퇴 처리되었습니다.');
    history.replace('');
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LoggedInMyPagePresenter
      userInfo={userInfo}
      isNotiOn={isNotiOn}
      notiToggleHandler={notiToggleHandler}
      logoutHandler={logoutHandler}
      signoutHandler={signoutHandler}
    />
  );
};

export default LoggedInMyPage;
