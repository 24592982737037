import React from 'react';
import { Link } from 'react-router-dom';
import { BiLockOpenAlt } from 'react-icons/bi';

import * as S from './style';

const GoLogin = () => {
  return (
    <S.CheckBox>
      <S.CheckContentBox>
        <S.TryLoginBox>
          <p>
            로그인 후 다양한 기능들을 <br />
            이용해보세요{' '}
          </p>
        </S.TryLoginBox>
        <Link className="login" to="/account/login/">
          <S.TryLogin>
            <p>로그인하기</p>
          </S.TryLogin>
        </Link>
      </S.CheckContentBox>
      <S.CheckImage>
        <BiLockOpenAlt />
      </S.CheckImage>
    </S.CheckBox>
  );
};

export default GoLogin;
