import styled from 'styled-components';

export const MypageWrapper = styled.div`
  padding-bottom: 65px;
`;

// Header
export const Container = styled.div`
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  background-color: #fdf8f8;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-height: 740px) {
    padding-top: 1rem;
    padding-bottom: 1.2rem;
  }
`;
export const MyHeader = styled.div`
  width: 88%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  width: 100%;
  @media only screen and (max-width: 334px) {
    padding-top: 1rem;
  }
`;

export const P = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  @media only screen and (max-width: 285px) {
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 740px) {
    font-size: 1.6rem;
  }
`;

export const editBtn = styled.div`
  border: #cfcfcf solid 1px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  width: 3.5rem;
  height: 1.9rem;
  border-radius: 0.7rem;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  @media only screen and (max-width: 285px) {
    font-size: 11px;
    font-weight: 400;
    line-height: 1.2rem;
    width: 2.2rem;
    height: 1.3rem;
  }
`;

export const MoveToLeftDiv = styled.div`
  width: 100%;
`;

export const MyAlarmBox = styled.div`
  display: flex;
  width: 88%;
  background-color: white;
  padding: 20px;
  margin: 15px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
  a {
    font-size: 13px;
    color: #767676;
  }
`;

export const MyAlarmTextBox = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-self: flex-end;
  /* line-height: 48px; */
`;
export const AlarmWordWrapper = styled.div`
  p {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1px;
  }
  svg {
    font-size: 1.3rem;
    margin-left: 4px;
  }
`;

export const MyAlarmHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 35px;
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 340px) {
    div {
      span {
        padding-top: initial;
      }
    }
  }
`;
export const MyAlarmFooter = styled.div`
  display: flex;
  height: 20px;
  margin-top: 3px;
`;

export const UserInfoContainer = styled.div`
  margin-top: 1.2rem;
  display: flex;
  white-space: normal;
  flex-wrap: wrap;
`;

export const infoItem = styled.p`
  font-size: 1rem;
  padding: 0.25rem 0.3rem;
  white-space: nowrap;
  color: #484848;
  @media only screen and (max-height: 290px) {
    font-size: 1rem;
    padding: 0.2rem 0.6rem;
  }
  @media only screen and (max-width: 285px) {
    font-size: 1rem;
    padding: 0.15rem 0.55rem;
  }
`;

// Menu
export const MenuContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
`;

export const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  width: 88%;
`;

export const MenuEl = styled.a`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0.8rem 0;
  color: black;
  width: fit-content;
  cursor: pointer;
  @media only screen and (max-width: 450px) {
    font-size: 1rem;
    margin: 0.6rem 0;
  }
`;

// 토글 버튼
export const ToggleBtnWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-right: 2.3rem;
  height: 68%;
  @media only screen and (max-width: 285px) {
    margin-right: 0.3rem;
  }
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 3.1rem;
  height: 1.7rem;
  border-radius: 1.4rem;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 1.4rem;
    height: 1.4rem;
    margin: 0.15rem;
    background: #ffffff;
    box-shadow: 0.08rem 0.2rem 0.2rem 0.08rem rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
  @media only screen and (max-width: 285px) {
    margin-top: 3px;
    width: 2.5rem;
    height: 1.4rem;
    &::after {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 1rem;
  width: 1.3rem;
  height: 1.1rem;
  &:checked + ${CheckBoxLabel} {
    background: #c02e2e;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 1.5rem;
      transition: 0.2s;
    }
  }
  @media only screen and (max-width: 285px) {
    &:checked + ${CheckBoxLabel} {
      background: #c02e2e;
      &::after {
        width: 1rem;
        height: 1.1rem;
        margin-left: 1.3rem;
      }
    }
  }
`;

// NotiContainer
export const NotiContainer = styled.div`
  background-color: #fafafa;
  font-size: 1.2rem;
  padding: 1.4rem;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 334px) {
    font-size: 80%;
  }
`;

export const NotiP = styled.p`
  font-weight: 400;
  color: #484848;
  margin-bottom: 0.6rem;
  @media only screen and (max-height: 740px) {
    font-size: 0.83rem;
  }
`;

export const NotiP2 = styled.p`
  font-weight: 600;
  margin-left: 0.2rem;
  @media only screen and (max-height: 740px) {
    font-size: 0.8rem;
  }
`;

export const NotiDiv = styled.div`
  max-width: 7rem;
  font-size: 1rem;
  height: 1.8rem;
  background-color: #484848;
  color: #fdf8f8;
  text-align: center;
  line-height: 1.65rem;
  border-radius: 0.5rem;
  margin-top: 0.6rem;
  cursor: pointer;
`;
