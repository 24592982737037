import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { throttle } from 'lodash';
import qs from 'qs';
import { useParams, useLocation } from 'react-router-dom';
import { Footer, Loading, GoLoginPage } from '../../components/Common';
import CourseCard from '../../components/Search/CourseCard';
import FilterSelect from '../../components/Common/Filter';
import { ErrorPage } from '..';
import * as S from '../Search/style.jsx';
import * as Ss from '../../components/Search/style.jsx';
import { AdvancedSearchContainer } from '../../components/Search';
import { getAdvancedSearchApi } from '../../utils';
import AdvancedSearchHeader from '../../components/Search/AdvancedSearchHeader';
import { checkPresent } from '../../utils/checkPresent';

const AdvancedSearch = () => {
  const { regStatus } = useParams();
  const location = useLocation();

  const { year, term, clas, queryBy, query } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // console.log('Advanced Search', year, term, clas, queryBy, query);

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [info, setInfo] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [isTwoLine, setIsTwoLine] = useState(true);

  const [totalCount, setTotalCount] = useState(0);
  // const [nextPage, setNextPage] = useState(1);
  let nextPage = 1;
  let pageCount = 0;
  const [fetching, setFetching] = useState(false); // 추가 데이터를 로드하는지 아닌지를 담기위한 state

  const isPresent = checkPresent(year, term);
  const getIsTwoLine = (colCd, queryBy) => {
    // console.log(colCd, queryBy, isTwoLine);
    if (colCd == null && queryBy == null) {
      // console.log('여기');
      setIsTwoLine(false);
    }
  };

  // 스크롤 이벤트 핸들러
  const handleScroll = throttle(async () => {
    // console.log(pageCount);
    // console.log(nextPage);
    const { scrollHeight } = document.querySelector('#root');
    const { scrollTop } = document.querySelector('#root');
    const { clientHeight } = document.querySelector('#root');
    if (
      scrollTop + clientHeight + 10 >= scrollHeight &&
      fetching === false &&
      pageCount + 20 > nextPage * 20
    ) {
      // 페이지 끝에 도달하면 추가 데이터를 받아온다
      fetchMoreCourses();
    }
  }, 300);

  const fetchMoreCourses = async () => {
    // 추가 데이터를 로드하는 상태로 전환
    setFetching(true);

    // API로부터 받아온 페이징 데이터를 이용해 다음 데이터를 로드
    getAdvSearchInfo();
    // 추가 데이터 로드 끝
    setFetching(false);
  };

  // 상세검색결과 API 호출
  const getAdvSearchInfo = async (sortForFilter = 'favorite') => {
    // setIsLoading(true);
    const [data, error] = await getAdvancedSearchApi(
      regStatus,
      year,
      term,
      clas,
      queryBy,
      query,
      sortForFilter,
      nextPage,
    );
    if (data) {
      // if (data.totalCount > 200) {
      //   window.alert(
      //     '검색 결과가 너무 많습니다. 상위 200개 과목만 출력합니다.',
      //   );
      // }
      // console.log(data);
      // console.log(data.totalCount);
      nextPage = data.nextPage;
      pageCount = data.totalCount;
      setTotalCount(data.totalCount);
      getIsTwoLine(data.info.clas.colCd, data.info.queryBy);
      setIsLogin(data.auth.success);
      setResult((result) => result.concat(data.result));
      setInfo(data.info);
      setIsLoading(false);
    } else if (error.auth.success) {
      alert('에러가 발생했습니다!!');
      window.location.replace('/');
    } else {
      // console.log(info);
      setIsLogin(error.auth.success);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    setIsTwoLine(true);
    document.querySelector('#root').addEventListener('scroll', handleScroll);
    if (regStatus === 'register' || regStatus === 'prereg') {
      fetchMoreCourses();
    }
    return () => {
      // scroll event listener 해제
      document
        .querySelector('#root')
        .removeEventListener('scroll', handleScroll);
    };
  }, [regStatus, year, term, clas, queryBy, query]);

  if (regStatus !== 'register' && regStatus !== 'prereg') {
    return <ErrorPage />;
  }
  if (isLoading) {
    return <Loading />;
  }
  const onFilterSelect = async (e) => {
    onFilterSelect(e.target.value);
  };
  return (
    <>
      <S.AdvancedSearchPageContainer isTwoLine={isTwoLine}>
        {isLoading && <Loading />}
        {info && <AdvancedSearchHeader regStatus={regStatus} info={info} />}
        {result && !isLoading && (
          <AdvancedSearchContainer
            regStatus={regStatus}
            result={result}
            totalCount={totalCount}
            isPresent={isPresent}
            sort={info.sort || ''}
            updateResult={getAdvSearchInfo}
          />
        )}
        {!isLogin && <GoLoginPage loc="search" />}
      </S.AdvancedSearchPageContainer>
      <Footer />
    </>
  );
};

export default React.memo(AdvancedSearch);
