import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '..';
import { Footer, GoLoginPage, Loading } from '../../components/Common';
import {
  FavoritesContainer,
  FavoritesHeader,
} from '../../components/Favorites';
import { getMyBookmarksApi } from '../../utils';
import * as S from './style.jsx';

const Favorites = () => {
  const { regStatus } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState();
  const [markedCount, setMarkedCount] = useState(0);
  // const { regStatus } = match.params;
  const getMywish = async () => {
    if (localStorage.getItem('access-token')) {
      const [data, error] = await getMyBookmarksApi(regStatus);
      if (data) {
        setResult(data);
        setMarkedCount(data.myMarksCount);
        setIsLoggedIn(data.auth.success);
      } else {
        // eslint-disable-next-line no-console
        console.dir(error);
        setIsLoggedIn(error.auth.success);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getMywish();
  }, []);

  if (regStatus !== 'register' && regStatus !== 'prereg') {
    return <ErrorPage />;
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <S.FavoritesPageContainer>
        <FavoritesHeader markedCount={markedCount} regStatus={regStatus} />
        {isLoggedIn ? (
          <FavoritesContainer favInfo={result} regStatus={regStatus} />
        ) : (
          <GoLoginPage loc="favorite" />
        )}
        <Footer />
      </S.FavoritesPageContainer>
    </>
  );
};

export default React.memo(Favorites);
