import React from 'react';
// import { GeneralHeader } from '../../components/Common';
import { TextBox, LoginForm, SubLink } from '../../components/Login';
import * as S from './style';

const Login = () => {
  return (
    <S.LoginContainer>
      <TextBox />
      <LoginForm />
      <SubLink />
    </S.LoginContainer>
  );

  // <Loading />
};

export default Login;
