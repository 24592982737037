import React, { useEffect, useState, useCallback } from 'react';
import { IoClose } from 'react-icons/io5';
import { createPortal } from 'react-dom';
import * as S from './style';
import { editBookmarkApi } from '../../utils';
import ConfirmModal from './ConfirmModal';
import { checkPresent } from '../../utils/checkPresent';

function CourseModal({
  regStatus,
  course,
  closeModal,
  isMarked,
  editBookmark,
  isNotiOn,
  handleNotiToggle,
}) {
  const { courseInfo, status, ...sub } = course;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const isReg = regStatus === 'register';
  const isPast = !checkPresent(
    String(courseInfo.year),
    String(courseInfo.term),
  );
  const isCourse = true;

  useEffect(() => {
    // console.log(isPast);
    // 마이페이지 갔다가 정보수정 왔을 때, 모달이 열리지 않는 에러때문에 0.01초동안은 안닫히게 설정.
    setTimeout(() => {
      window.addEventListener('click', handleClickOutside);
    }, 10);
    document.querySelector('#root').style.overflow = 'hidden';
    return () => {
      window.removeEventListener('click', handleClickOutside);
      document.querySelector('#root').style.overflow = 'scroll';
      document.querySelector('html').style.background = '';
    };
  }, [isPast, isMarked]);

  const handleClickOutside = ({ target }) => {
    if (!document.querySelector('#modal').contains(target)) {
      closeModal();
    }
  };
  // 복사 기능
  let timer;
  const onClickCourse = useCallback(
    (e) => {
      const targetText = e.target.textContent;
      copyText(targetText);
      setConfirmText(`${targetText}이(가) 복사되었습니다.`);

      setIsConfirmModalOpen(true);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setIsConfirmModalOpen(false);
      }, 2500);
    },
    [timer],
  );

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // console.dir(text, '가 복사되었습니다.');
      })
      .catch(() => {
        alert('복사에 실패했습니다.');
      });
  };

  const handleBlankSyllabus = (e) => {
    e.preventDefault();
    alert('강의계획안이 존재하지 않는 강의입니다.');
    return false;
  };

  return createPortal(
    <>
      <S.CmBackdrop onClick={closeModal} />
      <S.CmContainer isPast={isPast}>
        <S.CmWrapper isPast={isPast}>
          <S.CmHeader>
            <S.CmTitleBox>
              <p>
                {courseInfo.year}년 {courseInfo.term}학기{' '}
                {isReg ? '수강신청' : '수강희망'}
              </p>
            </S.CmTitleBox>
            <S.CmCloseBox onClick={closeModal}>
              <IoClose />
            </S.CmCloseBox>
          </S.CmHeader>
          <S.CmMain>
            <S.CmCodeNotifBox>
              <S.CmCodeBox className="courseCode" onClick={onClickCourse}>
                {/* <S.CmCodeBox className="courseCode"> */}
                {courseInfo.courCd}-{courseInfo.courCls}
              </S.CmCodeBox>
              <S.CmSubInfoBox>
                {isReg && !isPast && (
                  <S.AlarmToggleContainer>
                    <S.AlarmToggleLabel onClick={(e) => e.stopPropagation()}>
                      알림
                    </S.AlarmToggleLabel>
                    <S.ToggleBtnWrapper onClick={(e) => e.stopPropagation()}>
                      <S.CCheckBox
                        id="checkbox"
                        type="checkbox"
                        checked={isNotiOn}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNotiToggle();
                        }}
                      />
                      <S.CheckBoxLabel htmlFor="checkbox" />
                    </S.ToggleBtnWrapper>
                  </S.AlarmToggleContainer>
                )}
                {isPast && isReg && (
                  <>
                    <S.CmNotificationIcon />
                    <S.CmNotificationNum>{sub.notiCount}</S.CmNotificationNum>
                  </>
                )}
                {isPast && (
                  <>
                    <S.CmFavoritesIcon />
                    <S.CmNotificationNum>{sub.markedCount}</S.CmNotificationNum>
                  </>
                )}
              </S.CmSubInfoBox>
            </S.CmCodeNotifBox>
            <S.CmNameBox className="courseName" onClick={onClickCourse}>
              {/* <S.CmNameBox className="courseName"> */}
              {courseInfo.courNm}
            </S.CmNameBox>
            <S.CmProfBox>{courseInfo.profNm}</S.CmProfBox>
            <S.CmTimeRoomBox>
              {courseInfo.timeRoom.replace(/<br>\n/gi, ' / ')}
            </S.CmTimeRoomBox>
          </S.CmMain>
          <S.CmContent>
            {isReg || (
              <S.CmRateBox fullColor={status.isFull}>
                <p>전체 경쟁률</p>
                <span>{status.rate || '-'}:1</span>
              </S.CmRateBox>
            )}
            <S.CmTotalBox
              isFullCourse={
                status.totalLimit - status.exchangeLimit <=
                status.totalApply - status.exchangeApply
              }
            >
              <p id="bold">전체 인원</p>
              <span>
                {status.totalApply === '-'
                  ? '-'
                  : `${status.totalApply - status.exchangeApply}+${
                      status.exchangeApply
                    }`}
              </span>
              <span>
                /
                {status.totalLimit === '-'
                  ? '-'
                  : `${status.totalLimit - status.exchangeLimit}+${
                      status.exchangeLimit
                    }`}
              </span>
            </S.CmTotalBox>
            <S.CmGradeWrapper>
              <S.CmGradeBox isFullColor={status.oneLimit <= status.oneApply}>
                <p>1학년</p>
                <span>{status.oneApply}</span>
                <span>/{status.oneLimit}</span>
              </S.CmGradeBox>
              <S.CmGradeBox isFullColor={status.twoLimit <= status.twoApply}>
                <p>2학년</p>
                <span>{status.twoApply}</span>
                <span>/{status.twoLimit}</span>
              </S.CmGradeBox>
              <S.CmGradeBox
                isFullColor={status.threeLimit <= status.threeApply}
              >
                <p>3학년</p>
                <span>{status.threeApply}</span>
                <span>/{status.threeLimit}</span>
              </S.CmGradeBox>
              <S.CmGradeBox isFullColor={status.fourLimit <= status.fourApply}>
                <p>4학년</p>
                <span>{status.fourApply}</span>
                <span>/{status.fourLimit}</span>
              </S.CmGradeBox>
              <S.CmGradeBox
                isFullColor={status.exchangeLimit <= status.exchangeApply}
              >
                <p>교환학생</p>
                <span>{status.exchangeApply}</span>
                <span>/{status.exchangeLimit}</span>
              </S.CmGradeBox>
              <S.CmGradeBox isFullColor={status.gradLimit <= status.gradApply}>
                <p>대학원생</p>
                <span>{status.gradApply}</span>
                <span>/{status.gradLimit}</span>
              </S.CmGradeBox>
            </S.CmGradeWrapper>
            {courseInfo.syllabus === null ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <S.CoursePlanLink
                className="coursePlan"
                href=""
                onClick={handleBlankSyllabus}
              >
                강의계획안 보러가기 &gt;
              </S.CoursePlanLink>
            ) : (
              <S.CoursePlanLink
                className="coursePlan"
                href={courseInfo.syllabus}
                target="_blank"
                rel="noopener noreferrer"
              >
                강의계획안 보러가기 &gt;
              </S.CoursePlanLink>
            )}
          </S.CmContent>
        </S.CmWrapper>
        {isPast || (
          <S.CmFooter
            isBookmarked={isMarked}
            onClick={() => editBookmark(regStatus, courseInfo.id)}
          >
            <S.CmBookmarkBox>
              {isMarked ? (
                <S.CmBookmarkParagraph>즐겨찾기 해제</S.CmBookmarkParagraph>
              ) : (
                <>
                  <S.CmBookmarkParagraph>즐겨찾기</S.CmBookmarkParagraph>
                  <S.CcFavoritesIcon />
                </>
              )}
            </S.CmBookmarkBox>
          </S.CmFooter>
        )}

        {isConfirmModalOpen && confirmText && (
          <ConfirmModal
            text={confirmText}
            setShowModal={setIsConfirmModalOpen}
            isCourse={isCourse}
          />
        )}
      </S.CmContainer>{' '}
    </>,
    document.getElementById('modal'),
  );
}

export default CourseModal;
