/* eslint-disable no-return-await */
import { API_URLS } from '../constants';
import { withoutAuthInstance } from './common';

const { TOKEN_OBTAIN, TOKEN_REFRESH } = API_URLS;

// 토큰발급
export const obtainToken = async (inputData) => {
  // obtain new access, refresh token with username, password info.
  const newData = await withoutAuthInstance.post(TOKEN_OBTAIN, inputData);
  // console.dir(newData);
  // set access, refresh token on localStorage
  // localStorage.setItem('access-token', newData.access);
  // localStorage.setItem('refresh-token', newData.refresh);

  return newData;
};

export const hasToken = () => {
  const accessToken = localStorage.getItem('access-token');
  const refreshToken = localStorage.getItem('refresh-token');

  if (accessToken && refreshToken) {
    return true;
  }
  return false;
};

export const getToken = () => {
  const accessToken = localStorage.getItem('access-token');
  const refreshToken = localStorage.getItem('refresh-token');

  // const data = {
  //   access: accessToken,
  //   refresh: refreshToken,
  // };
  return [accessToken, refreshToken];
};

export const tokenRefresh = async (refresh) => {
  const inputData = {
    refresh,
  };
  const newData = await withoutAuthInstance.post(TOKEN_REFRESH, inputData);
  return newData;
};
