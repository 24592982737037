import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from '../style';
import ConfirmModal from '../../Modal/ConfirmModal';
import { obtainToken } from '../../../utils/auth';

const PLoginForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const history = useHistory();
  const [showBtn, setShowBtn] = useState('password');
  const [showhide, setShowhide] = useState('SHOW');
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState('');

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const HideBtn = () => {
    if (showBtn === 'password') {
      setShowBtn('text');
      setShowhide('HIDE');
    } else {
      setShowBtn('password');
      setShowhide('SHOW');
    }
  };
  let timer;
  const loginHandler = useCallback(
    async (e) => {
      e.preventDefault();
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('uuid');
      const [data, error] = await obtainToken(inputs);
      if (data) {
        if (data.isUser) {
          localStorage.setItem('access-token', data.access);
          localStorage.setItem('refresh-token', data.refresh);
          history.push('/');
        } else if (window.confirm(data.msg)) {
          localStorage.setItem('uuid', data.kopasUuid);
          history.push('/account/signup/');
        }
      } else {
        // eslint-disable-next-line no-console
        console.dir(error);
        setMsg(error.msg);
        setShowModal(true);
        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          setShowModal(false);
        }, 2000);
      }
    },
    [timer, inputs],
  );
  return (
    <S.FormContainer>
      <S.IdTag>
        <img
          src="https://ku-sugang.s3.ap-northeast-2.amazonaws.com/icon/id.svg"
          alt="아이디 아이콘"
        />
        <S.LoginInput1
          value={inputs.username}
          name="username"
          type="text"
          placeholder="아이디"
          onChange={onChange}
        />
      </S.IdTag>
      <S.PasswordTag>
        <img
          src="https://ku-sugang.s3.ap-northeast-2.amazonaws.com/icon/password.svg"
          alt="비밀번호 아이콘"
        />
        <S.LoginInput2
          value={inputs.password}
          id="password"
          name="password"
          type={showBtn}
          placeholder="비밀번호"
          onChange={onChange}
        />
        <S.ShowBtn onClick={HideBtn}>{showhide}</S.ShowBtn>
      </S.PasswordTag>

      <S.LoginButton type="submit" onClick={(e) => loginHandler(e)}>
        로그인
      </S.LoginButton>
      {showModal && <ConfirmModal text={msg} />}
    </S.FormContainer>
  );
};

export default PLoginForm;
