import React from 'react';
import * as S from './style';

const ConfirmModal = ({ text, isCourse, isCourseCard }) => {
  // isCourse은 코스카드에서만 사용합니다.
  return (
    <S.ModalWrapper isCourse={isCourse} isCourseCard={isCourseCard}>
      <S.ConfirmModal isCourse={isCourse}>{text}</S.ConfirmModal>
    </S.ModalWrapper>
  );
};

export default ConfirmModal;
