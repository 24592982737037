import React from 'react';
import SearchSection from '../SearchSection';
import * as S from '../style';

const PSearchContainer = ({ queryText, regStatus, info, isPresent }) => {
  return (
    <S.ScContainer>
      <S.ScWrapper>
        <S.ScTitle>과목명</S.ScTitle>
        <p>
          &apos;{queryText}&apos;에 대한 검색 결과는 총 &apos;
          {info.courNm && info.courNm.courCount}&apos;건 입니다.
        </p>
        <SearchSection
          regStatus={regStatus}
          info={info.courNm}
          isPresent={isPresent}
        />
        <S.ScTitle>교수명</S.ScTitle>
        <p>
          &apos;{queryText}&apos;에 대한 검색 결과는 총 &apos;
          {info.courNm && info.profNm.courCount}&apos;건 입니다.
        </p>
        <SearchSection
          regStatus={regStatus}
          info={info.profNm}
          isPresent={isPresent}
        />
        <S.ScTitle>학수번호</S.ScTitle>
        <p>
          &apos;{queryText}&apos;에 대한 검색 결과는 총 &apos;
          {info.courNm && info.courCd.courCount}&apos;건 입니다.
        </p>
        <SearchSection
          regStatus={regStatus}
          info={info.courCd}
          isPresent={isPresent}
        />
      </S.ScWrapper>
    </S.ScContainer>
  );
};
export default PSearchContainer;
