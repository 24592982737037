import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: -webkit-fill-available;
  padding-top: 10px;
  div:nth-child(1) {
    margin-bottom: 30px;
    margin-left: 22px;
    svg {
      font-size: 2rem;
    }
  }
`;

export const LoginHeading = styled.div`
  font-weight: 700;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  font-size: 32px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.32px;
  text-align: left;
  color: #000000;
  & > div:first-child > p {
    color: #000000;
  }
  & > div:nth-child(2) > p {
    color: #000000;
  }
  & > div:nth-child(2) > p > img {
    width: 38px;
    vertical-align: text-bottom;

    padding-left: 4px;
  }
  & > div > p > span {
    color: #000000;
    margin-right: 12px;
    height: min-content;
  }
`;

export const LoginText = styled.div`
  width: -webkit-fill-available;
  margin-top: 8px;
  padding: 0px 30px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  text-align: left;
`;
export const FormContainer = styled.form`
  animation: fadeIn 0.7s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 45px 34px 12px 30px;
  margin-top: 20px;
  img {
    width: 25px;
  }
`;
export const IdTag = styled.div`
  display: flex;
  width: -webkit-fill-available;
  padding-left: 10px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 0.1rem solid black;
  padding-bottom: 0.3rem;
`;
export const ShowBtn = styled.div`
  position: absolute;
  right: -6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #a0a0a0;
  cursor: pointer;
  margin-right: 20px;
`;
export const PasswordTag = styled.div`
  position: relative;
  display: flex;
  width: -webkit-fill-available;
  padding-left: 10px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 0.1rem solid black;
  margin-top: 20px;
  padding-bottom: 0.3rem;
`;

export const LoginInput1 = styled.input`
  min-width: 100px;
  width: -webkit-fill-available;
  border: none;
  padding: 12px 12px 9px 20px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: black;
  outline: none;
  text-decoration: none;
  ::placeholder {
    color: #a0a0a0;
  }
`;
export const LoginInput2 = styled.input`
  min-width: 100px;
  width: -webkit-fill-available;
  border: none;
  padding: 12px 0px 9px 20px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: black;
  outline: none;
  text-decoration: none;
  ::placeholder {
    color: #a0a0a0;
  }
`;
export const LoginButton = styled.button`
  border: none;
  outline: none;
  margin-top: 40px;
  width: -webkit-fill-available;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.19px;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  background: #484848;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 19%);
`;
export const LinkBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  margin-top: 15px;
  color: #d0cfcf;
  font-weight: 500;
  margin-right: 15px;
`;
export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0.5px;
  text-align: left;
  color: #cfcfcf;
  margin: 0px 10px;
  width: 47%;
  text-align: -webkit-center;
  @media only screen and (max-width: 345px) {
    font-size: 12px;
  }
`;
