import React from 'react';
import { Footer } from '../../components/Common';
import { FlHeader, FlSection } from '../../components/FeedList';
import { ErrorPage } from '..';

const FeedList = ({ match }) => {
  const { category } = match.params;
  if (category !== 'notice' && category !== 'story') {
    return <ErrorPage />;
  }
  return (
    <>
      <FlHeader category={category} />
      <FlSection category={category} />
      <Footer />
    </>
  );
};

export default FeedList;
