import styled, { css } from 'styled-components';

// Favorites Header
export const FhContainer = styled.div`
  width: -webkit-fill-available;
  max-width: 450px;
  position: fixed;
  top: 0;
  box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 16%);
  background-color: white;
  z-index: 2;
`;

export const FhName = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
  width: 85%;
  height: 30%;
  margin: 25px auto 5px auto;
  padding-bottom: 0.5rem;
  border-bottom: 1.5px solid #cfcfcf;
  position: relative;
  h2 {
    margin-right: 1rem;
    font-size: 1.375rem;
    span {
      color: ${(props) => (props.isNow ? '#c02e2e' : '#828282')};
    }
  }
  span:nth-child(2) {
    color: ${(props) => (props.isNow ? '#c02e2e' : '#828282')};
  }
  svg {
    color: #484848;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 2px;
  }
  @media only screen and (max-width: 345px) {
    margin: 30px auto 0px auto;
    h2 {
      font-size: 1.1rem;
      margin-right: 0.5rem;
    }
    svg {
      font-size: 1.2rem;
    }
  }
`;

export const FhRedText = styled.span`
  font-size: 1.375rem;
  font-weight: 700;
  margin-right: 0.5rem;
  @media only screen and (max-width: 345px) {
    font-size: 1.1rem;
    margin-right: 0.2rem;
  }
`;

export const FhDarkGrayText = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  color: #333333;
  @media only screen and (max-width: 345px) {
    font-size: 0.97rem;
  }
`;

export const FhBottom = styled.div`
  margin: 0.1rem 1.3rem auto;
  height: 35%;
`;

export const FhRegWrapper = styled.div`
  width: 80%;
  @media only screen and (max-width: 345px) {
    width: 70%;
  }
  ${(props) =>
    props.regStatus === 'register'
      ? css`
          button:nth-child(2) {
            div {
              visibility: visible;
              background-color: #c02e2e;
              width: 80%;
              height: 5px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              bottom: 0px;
              position: absolute;
              @media only screen and (max-width: 345px) {
                width: 75%;
              }
            }
            color: #c02e2e;
            font-weight: 700;
          }
        `
      : css`
          button:nth-child(1) {
            div {
              visibility: visible;
              background-color: #c02e2e;
              width: 80%;
              height: 5px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              bottom: 0px;
              position: absolute;
              @media only screen and (max-width: 345px) {
                width: 75%;
              }
            }
            color: #c02e2e;
            font-weight: 700;
          }
        `}
`;

export const FhRegButton = styled.button`
  border: none;
  width: 80px;
  cursor: pointer;
  height: 2.9rem;
  color: #a0a0a0;
  background-color: white;
  margin-right: 13px;
  padding: 0 0.5rem;
  border-radius: 18px 18px 0 0;
  font-weight: 600;
  font-size: 0.9rem;
  position: relative;
  @media only screen and (max-width: 345px) {
    margin-right: 10px;
    font-size: 0.7rem;
    height: 2.4rem;
    width: 65px;
  }
`;

// Favorites Container
export const FcContainer = styled.div`
  background-color: #f7f7f7;
  padding-top: 130px;
  & > div {
    animation: fadeIn 0.5s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 3;
        transform: none;
      }
    }
    width: 100%;
    min-height: 82vh;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  @media only screen and (max-width: 345px) {
    padding-top: 110px;
  }
`;

export const FcEmptyBox = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
  svg {
    width: 100%;
    height: 100%;
    color: #484848;
  }
`;

export const FcMsgWrapper = styled.div`
  height: 50%;
  text-align: -webkit-center;
  padding: 10rem 5rem 5rem 5rem;
  p {
    font-size: 1rem;
    font-weight: 500;
    color: #484848;
  }
  @media only screen and (max-width: 375px) {
    padding: 10rem 1rem 5rem 1rem;
    p {
      font-size: 0.8rem;
    }
  }
`;

export const FcCardsWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1.5rem 5.8rem 1.5rem;
  background-color: #f7f7f7;
  display: grid;
  column-gap: 0.9rem;
  row-gap: 0.9rem;
`;
