import React from 'react';

import * as S from './style';
import Header from './Header';
import Menu from './Menu';

const LoggedOutMyPage = () => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <S.MypageWrapper>
        <Header />
        <Menu />
      </S.MypageWrapper>
    </>
  );
};

export default React.memo(LoggedOutMyPage);
