import styled from 'styled-components';

export const MypageWrapper = styled.div`
  padding-bottom: 65px;
`;

// Header
export const HeaderContainer = styled.div`
  width: 100%;
  padding: 2rem;
  animation: fadeIn 0.5s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  padding-top: 2.5rem;
  padding-bottom: 6rem;
  background-color: #fdf8f8;
`;

export const Headline = styled.div`
  cursor: pointer;
  border-bottom: 0.123rem solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  svg {
    margin-top: 4px;
  }
`;

export const P = styled.p`
  font-size: 1.5rem;
  font-weight: 660;
`;

export const P2 = styled.p`
  font-size: 0.85rem;
  padding-top: 0.9rem;
  color: #767676;
`;

// Menu

export const MenuContainer = styled.div`
  padding: 1.5rem;
`;

export const MenuList = styled.div`
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

export const MenuEl = styled.a`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.8rem 0;
  color: black;
  width: fit-content;
  cursor: pointer;
  @media only screen and (max-width: 450px) {
    font-size: 1rem;
  }
`;
