/* eslint-disable react/no-array-index-key */

import React from 'react';
import { RiBookMarkLine } from 'react-icons/ri';
import { CourseCard } from '../../Search';
import * as S from '../style';

const PFavoritesContainer = ({ favInfo, regStatus, isPresent }) => {
  return (
    <S.FcContainer>
      <div>
        {favInfo && favInfo.myMarksCount === 0 && (
          <S.FcMsgWrapper>
            <S.FcEmptyBox>
              <RiBookMarkLine />
            </S.FcEmptyBox>
            <p>즐겨찾기에 과목을 추가하고</p>
            <p>고파스 앱에서 알림 받아보세요!</p>
          </S.FcMsgWrapper>
        )}
        <S.FcCardsWrapper>
          {favInfo &&
            favInfo.markedList.map((course) => (
              <CourseCard
                key={course.courseInfo.id}
                regStatus={regStatus}
                course={course}
                $ismain={false}
                isPresent={isPresent}
              />
            ))}
        </S.FcCardsWrapper>
      </div>
    </S.FcContainer>
  );
};

export default PFavoritesContainer;
