import React from 'react';

import CourseCard from '../CourseCard';
import * as S from '../style';
import { getAdvancedSearchApi } from '../../../utils';
import FilterSelect from '../../Common/Filter';

const AdvancedSearchContainer = ({
  regStatus,
  result,
  totalCount,
  isPresent,
  sort,
  updateResult,
}) => {
  const onFilterSelect = async (e) => {
    updateResult(e.target.value);
  };
  return (
    <S.AscContainer>
      <S.ScWrapper>
        <S.SsContainer>
          <S.AscSortResultTextBox>
            <S.AscParagraph>
              상세검색 결과는 총 &apos;{totalCount}&apos;건 입니다.
            </S.AscParagraph>
            <FilterSelect
              selectValue1=""
              selectText1="기본 순"
              selectValue2="favorite"
              selectText2="담은 순"
              value={sort}
              handleP={onFilterSelect}
            />
          </S.AscSortResultTextBox>
          {result.map((course) => (
            <CourseCard
              key={course.courseInfo.id}
              regStatus={regStatus}
              course={course}
              isPresent={isPresent}
            />
          ))}
        </S.SsContainer>
      </S.ScWrapper>
    </S.AscContainer>
  );
};

export default AdvancedSearchContainer;
