/* eslint-disable no-return-await */
import { API_URLS } from '../constants';
import { getToken } from './auth';
import { withAuthInstance, withoutAuthInstance } from './common';

const { GET_MAIN_INFO, GET_FEED_LIST, GET_FEED_DETAIL, LIKE_FEED, HIT_FEED } =
  API_URLS;

// 메인 페이지 데이터
export const getMainInfoApi = async (category) => {
  const newData = await withoutAuthInstance.get(`${GET_MAIN_INFO + category}`);
  return newData;
};

// 공지사항/수알 콘텐츠 목록 반환
export const getFeedListApi = async (category) => {
  const newData = await withAuthInstance.get(`${GET_FEED_LIST + category}/`);
  return newData;
};

// 게시물 상세페이지
export const getFeedDetailApi = async (category, postId) => {
  const newData = await withAuthInstance.get(
    `${GET_FEED_DETAIL + category}/${postId}`,
  );
  return newData;
};

// 게시물 좋아요 등록/해제
export const likeFeedApi = async (category, postId) => {
  const newData = await withAuthInstance.get(
    `${LIKE_FEED + category}/${postId}/like`,
  );
  return newData;
};

// 게시물 좋아요 등록/해제
export const hitFeedApi = async (postId) => {
  const newData = await withAuthInstance.get(`${HIT_FEED + postId}`);
  return newData;
};
