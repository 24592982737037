import React from 'react';
import PFavoritesContainer from './PFavoritesContainer';

const FavoritesContainer = ({ favInfo, regStatus }) => {
  const isPresent = true;
  return (
    <PFavoritesContainer
      favInfo={favInfo}
      regStatus={regStatus}
      isPresent={isPresent}
    />
  );
};

export default FavoritesContainer;
