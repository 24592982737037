import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { useParams, useLocation } from 'react-router-dom';
import { SearchContainer, SearchHeader } from '../../components/Search';
import {
  Footer,
  Loading,
  GoLogin,
  GoLoginPage,
  GoExp,
} from '../../components/Common';
import * as S from './style.jsx';
import { getClassInfoApi } from '../../utils/class';
import { ErrorPage } from '..';
import { checkPresent } from '../../utils/checkPresent';

const Search = () => {
  const { regStatus } = useParams();
  const location = useLocation();
  const { queryText, year, term } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isPresent = checkPresent(year, term);

  const getCourseInfo = async () => {
    if (
      localStorage.getItem('access-token') ||
      (regStatus === 'register' && isPresent)
    ) {
      const [data, error] = await getClassInfoApi(
        regStatus,
        year,
        term,
        queryText,
      );
      if (data) {
        if (data.result.totalCount > 99) {
          alert('검색 결과가 많아 상위 100개 과목만 반환합니다.');
        }
        setIsLoggedIn(data.auth.success);
        setResult(data.result);
      } else if (error.auth.success) {
        alert('에러가 발생했습니다!!');
        window.location.replace('/');
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    if (regStatus === 'register' || regStatus === 'prereg') {
      getCourseInfo();
    }
  }, [regStatus, year, term, queryText]);

  if (regStatus !== 'register' && regStatus !== 'prereg') {
    return <ErrorPage />;
  }
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <S.SearchPageContainer>
        <SearchHeader
          queryText={queryText}
          period={year + term}
          regStatus={regStatus}
        />
        {isLoggedIn && (
          <S.AdvancedSearchBox>
            <GoExp />
          </S.AdvancedSearchBox>
        )}
        {!isLoggedIn && regStatus === 'register' && isPresent && (
          <S.SearchLoginBox>
            <GoLogin />
          </S.SearchLoginBox>
        )}
        {!isLoggedIn && !(regStatus === 'register' && isPresent) && (
          <GoLoginPage loc="search" />
        )}
        {result && !isLoading && (
          <SearchContainer
            queryText={queryText}
            regStatus={regStatus}
            result={result}
            isPresent={isPresent}
          />
        )}
      </S.SearchPageContainer>
      <Footer />
    </>
  );
};

export default React.memo(Search);
