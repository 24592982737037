import React, { createContext, useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { IS_DEV } from './constants/DEFAULT_VALUE';
// eslint-disable-next-line import/named
import {
  Edit,
  ErrorPage,
  Favorites,
  FeedDetail,
  FeedList,
  Login,
  Main,
  MyPage,
  OauthKopas,
  Search,
  AdvancedSearch,
  Signup,
  DevPage,
} from './pages';

export const GlobalContext = createContext({});
const App = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  const [isOpening, setIsOpening] = useState(false);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isOpening,
    setIsOpening,
  };

  return (
    <>
      <GlobalStyle />
      <Switch>
        {IS_DEV && <Route component={DevPage} />}
        <Route path="/favorites/:regStatus/">
          <GlobalContext.Provider value={value}>
            <Favorites />
          </GlobalContext.Provider>
        </Route>
        <Route path="/search/:regStatus">
          <GlobalContext.Provider value={value}>
            <Search />
          </GlobalContext.Provider>
        </Route>
        <Route path="/advanced-search/:regStatus">
          <GlobalContext.Provider value={value}>
            <AdvancedSearch />
          </GlobalContext.Provider>
        </Route>
        <Route path="/account/login/" component={Login} />
        <Route path="/account/signup/" component={Signup} />
        <Route path="/account/mypage/" component={MyPage} />
        <Route path="/account/edit/" component={Edit} />
        <Route path="/oauth-kopas/:kopasUuid" component={OauthKopas} />
        <Route path="/feed/:category/:postPk/" component={FeedDetail} />
        <Route path="/feed/:category/" exact component={FeedList} />
        <Route path="/" exact>
          <GlobalContext.Provider value={value}>
            <Main />
          </GlobalContext.Provider>
        </Route>
        <Route component={ErrorPage} />
      </Switch>
    </>
  );
};

export default App;

const GlobalStyle = createGlobalStyle`
  /* noto-sans-kr-100 - korean */
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-100.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-100.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-100.woff') format('woff'), /* Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-100.ttf') format('truetype'), /* Safari, Android, iOS */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-100.svg#NotoSansKR') format('svg'); /* Legacy iOS */
  }
  /* noto-sans-kr-300 - korean */
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-300.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-300.woff') format('woff'), /* Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
  }
  /* noto-sans-kr-regular - korean */
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-regular.woff') format('woff'), /* Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
  }
  /* noto-sans-kr-500 - korean */
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-500.woff') format('woff'), /* Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
  }
  /* noto-sans-kr-700 - korean */
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-700.woff') format('woff'), /* Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
  }
  /* noto-sans-kr-900 - korean */
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-900.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-900.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-900.woff') format('woff'), /* Modern Browsers */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-900.ttf') format('truetype'), /* Safari, Android, iOS */
        url('https://ku-sugang.s3.ap-northeast-2.amazonaws.com/fonts/NotoSansKR/noto-sans-kr-v27-korean-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
  }
  
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box; 
    font-family: 'Noto Sans KR';
   
    
  }
  a {
    text-decoration: none;
  }
  body{
    display: flex;
    justify-content: center;
    align-items:center;
    background: linear-gradient(360deg, #FFECEC 0%, rgba(255, 255, 255, 0) 109.95%);
    overflow: hidden;
  }
  #container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1000px;
    min-width: 270px;
  }
  #root {
    width: 100%;
    max-width: 450px;
    min-width: 270px;
    margin: 0px auto;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
    background: #FFFFFF;
    height: 100vh; /* 혹시나 Custom Property 지원 안하는 브라우저를 위한 복귀점(Fallback) */
    height: calc(var(--vh, 1vh) * 100);

    overflow-x: hidden;
    overflow-y: scroll;
    ::-webkit-scrollbar {
    display: none;
    }
  }
  #aside {
    /* margin: 150px 0 0 0; */
    margin: auto;
    transition: all 1s;
    width:450px;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .App {
    height :100%;
  }
`;
