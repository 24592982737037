import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

import * as S from './style';

const GoBack = () => {
  const history = useHistory();
  const goBack = () => {
    history.replace('');
  };

  return (
    <S.GoBackContainer>
      <IoIosArrowBack onClick={goBack} />
    </S.GoBackContainer>
  );
};

export default GoBack;
