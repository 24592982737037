import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ErrorText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 100%;
  color: #cfcfcf;
`;
export const ErrorSubText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  margin-top: 10px;
  color: #cfcfcf;
`;
export const GoMainBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  background: #c02e2e;
  border-radius: 10px;
  margin-top: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: #fafafa;
`;
