import React, { useRef, useState, useEffect, useCallback } from 'react';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { likeFeedApi } from '../../../utils/feed';
import ConfirmModal from '../../Modal/ConfirmModal';
import CopyModal from '../../Modal/CopyModal';
import * as S from '../style';

const unLikedBtnStyle = {
  color: '#A0A0A0',
  cursor: 'pointer',
};
const isLikedBtnStyle = {
  color: '#C02E2E',
  cursor: 'pointer',
};

const ImageSlider = ({ images }) => {
  const settings = {
    slide: 'img',
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    draggable: true,
  };
  return (
    <S.FdSlider {...settings}>
      {images?.map((img) => (
        <img key={img.id} alt={`피드 이미지 #${img.id}`} src={img.url} />
      ))}
    </S.FdSlider>
  );
};

const Post = ({ category, post, postPk }) => {
  // Copy Modal
  const [modalSwitch, setModalSwitch] = useState(false);
  // 로그인하지 않은 사용자 Modal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [msg, setMsg] = useState('');
  const [likes, setLikes] = useState({
    isLiked: post.isLiked,
    likeCount: 0,
  });

  const pageUrl = useRef();

  let setter;
  const copyHandler = useCallback(() => {
    const el = pageUrl.current;
    el.select();
    document.execCommand('copy');
    setShowSuccessModal(true);
    setModalSwitch(true);
    if (setter) {
      clearTimeout(setter);
    }

    setter = setTimeout(() => {
      setModalSwitch(false);
    }, 2000);
  }, [setter]);

  let timer;
  const getLikes = useCallback(async () => {
    const [data, error] = await likeFeedApi(category, postPk);
    if (data) {
      setLikes({
        isLiked: data.isLiked,
        likeCount: data.likesCount,
      });
    } else {
      setMsg(error.msg);
      setShowLoginModal(true);
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        setShowLoginModal(false);
      }, 2000);
    }
  }, [category, postPk, timer]);

  const resultLikes =
    likes.isLiked === undefined ? post.isLiked : likes.isLiked;

  useEffect(() => {
    // console.log(modalSwitch);
  }, [modalSwitch]);

  return (
    <>
      {category === 'story' && (
        <S.FdCarouselWrapper>
          <ImageSlider images={post.images} />
        </S.FdCarouselWrapper>
      )}
      <S.FdPostWrapper>
        <S.FdDate>{post.createdDay}</S.FdDate>
        <S.FdTitle>
          <h4>{post.title}</h4>
        </S.FdTitle>
        <S.FdContent>
          <pre>{post.content}</pre>
        </S.FdContent>
        <S.FdFeatureBox>
          <S.FdLikeViewBox>
            <S.FdLike>
              {resultLikes ? (
                <AiFillHeart onClick={getLikes} style={isLikedBtnStyle} />
              ) : (
                <AiOutlineHeart onClick={getLikes} style={unLikedBtnStyle} />
              )}
              <S.FdFeatText>{likes?.likeCount || post.likesCount}</S.FdFeatText>
            </S.FdLike>
            <S.FdView>
              <S.FdViewIcon />
              <S.FdFeatText>{post.hit}</S.FdFeatText>
            </S.FdView>
          </S.FdLikeViewBox>
          <S.FdShareTextarea value={window.location.href} ref={pageUrl} />
          <S.FdShareIcon type="button" onClick={copyHandler} />
        </S.FdFeatureBox>
        <S.FdLoginMsgBox>
          {showLoginModal && (
            <ConfirmModal text={msg} setShowModal={setShowLoginModal} />
          )}
        </S.FdLoginMsgBox>
        {modalSwitch && (
          <CopyModal
            setModalSwitch={setModalSwitch}
            showSuccessModal={showSuccessModal}
            setShowSuccessModal={setShowSuccessModal}
          />
        )}
      </S.FdPostWrapper>
    </>
  );
};

const PFdPostContainer = ({ category, postPk, postDetail }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Post category={category} postPk={postPk} post={postDetail} />
    </>
  );
};

export default PFdPostContainer;
