/* eslint-disable no-return-await */
import { API_URLS } from '../constants';
import { withAuthInstance, withoutAuthInstance } from './common';

const {
  GET_CLASS_SEARCH,
  GET_ADVANCED_SEARCH,
  GET_CLASS_PERIOD,
  CHANGE_BOOKMARK,
  GET_BOOKMARK,
  GET_DETAIL_CLASSINFO,
  GET_CLASSIFICATION,
} = API_URLS;

// 검색 결과 반환
export const getClassInfoApi = async (regStatus, year, term, queryText) => {
  // console.log(regStatus, year, term, queryText, GET_CLASS_SEARCH);

  const newData = await withAuthInstance.get(
    `${GET_CLASS_SEARCH}${regStatus}?year=${year}&term=${term}&query_text=${queryText}`,
  );
  // console.log(newData[0]);
  // console.log(queryText);
  return newData;
};

// 상세 검색 결과 반환
export const getAdvancedSearchApi = async (
  regStatus,
  year,
  term,
  clas,
  queryBy,
  query,
  sort,
  page,
) => {
  // console.log('API', regStatus, year, term, clas, queryBy, query, sort);
  // console.log(queryBy, query);
  let queryString = `${GET_ADVANCED_SEARCH}${regStatus}?year=${year}&term=${term}&clas=${clas}&page=${page}`;
  if (queryBy) {
    queryString += `&query_by=${queryBy}&query_text=${query}`;
  }
  if (sort) {
    queryString += `&sort=${sort}`;
  }
  const newData = await withAuthInstance.get(queryString);
  // console.log('newData', newData[0]);
  return newData;
};

// 수강신청기간 반환
export const getClassPeriodApi = async () => {
  const newData = await withoutAuthInstance.get(GET_CLASS_PERIOD);
  return newData;
};

// 즐겨찾기 과목 반환
export const getMyBookmarksApi = async (regi) => {
  const newData = await withAuthInstance.get(`${GET_BOOKMARK}${regi}/`);
  return newData;
};

// 즐겨찾기 등록 / 해제
export const editBookmarkApi = async (regi, courseId) => {
  const newData = await withAuthInstance.get(
    `${CHANGE_BOOKMARK}${regi}/${courseId}`,
  );
  return newData;
};

// 개별강의알림 등록 / 해제
export const editNotifApi = async (courseId) => {
  const newData = await withAuthInstance.get(
    `${CHANGE_BOOKMARK}register/${courseId}/noti`,
  );
  return newData;
};

// 수강신청/희망 강의 세부 현황 조회 url 형식
export const getClassDetailApi = async (regi, year, term, courseId) => {
  const newData = await withAuthInstance.get(
    `${GET_DETAIL_CLASSINFO}${regi}/${year}/${term}/${courseId}`,
  );
  return newData;
};

// 상세 검색 모달의 이수구분 반환
export const getClasApi = async () => {
  const newData = await withoutAuthInstance.get(GET_CLASSIFICATION);
  return newData;
};
