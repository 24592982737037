import React from 'react';
import * as S from './style';

const FilterSelect = ({
  selectValue1,
  selectValue2,
  selectText1,
  selectText2,
  value,
  handleP,
}) => {
  return (
    <S.ShFilterWrapper>
      <S.ShFilter value={value} onChange={handleP}>
        <option value={selectValue1}>{selectText1}</option>
        <option value={selectValue2}>{selectText2}</option>
      </S.ShFilter>
    </S.ShFilterWrapper>
  );
};

export default FilterSelect;
