import React from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { createPortal } from 'react-dom';
import * as S from './style';

function AsidePage() {
  const moveToGopas = () => {
    window.open('https://www.koreapas.com/bbs/zboard.php?id=club');
  };
  return createPortal(
    <S.AsideWrapper>
      <S.ApMain>
        {/* <S.ApLogoBox logo={LOGO_URL} /> */}
        <S.ApLogoBox src="https://ku-sugang.s3.ap-northeast-2.amazonaws.com/logo/2023-icon.svg" />
        <S.ApLogoTextContainer>
          <S.ApLogoTextBox src="https://ku-sugang.s3.ap-northeast-2.amazonaws.com/logo/2023-word-logo.svg" />
        </S.ApLogoTextContainer>
        <S.ApMainSubtitle>
          과거 수강희망/수강신청 정보 확인부터 <br />
          수강희망 과목별 <span>경쟁률 실시간 비교</span>와<br />
          수강신청 과목별 <span>빈자리 알림 발송</span>까지
          <div className="divOne"> </div>
          <div className="divTwo"> </div>
        </S.ApMainSubtitle>
      </S.ApMain>
      <S.ApSub>
        <S.ApSubBoldTitle>이중전공과, 복수전공에 대한 궁금증</S.ApSubBoldTitle>
        <S.ApSubNormalTitle>
          해당 학과 학우들에게 직접 물어보자!
        </S.ApSubNormalTitle>
        <S.LinkBox onClick={moveToGopas}>
          <p>고파스 이중전공 게시판</p>
          <FiArrowUpRight style={{ fontSize: '30px' }} />
        </S.LinkBox>
      </S.ApSub>
    </S.AsideWrapper>,
    document.getElementById('aside'),
  );
}

export default React.memo(AsidePage);
