// 카카오 문의
export const ASK_URL = 'http://pf.kakao.com/_HCrYK';

// 노션
export const FAQ_URL =
  'https://ku-sugang.notion.site/FAQ-f4336d5b20d64d17a2bf0f503113e688';
export const NOTION_URL =
  'https://ku-sugang.notion.site/ku-sugang/9f2735e373e54e1aa5aed825405aa94b';

// 고파스
export const getNotiBoxURL = (kopasUuid) =>
  `https://www.koreapas.com/m/go_alimi.php?uuid=${kopasUuid}`;

// S3
export const S3_URL = 'https://ku-sugang.s3.ap-northeast-2.amazonaws.com/';
