import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { OauthKopasApi } from '../../utils/account';

const OauthKopas = ({ match }) => {
  const { kopasUuid } = match.params;
  const history = useHistory();

  const checkUser = async () => {
    const [data, error] = await OauthKopasApi({ kopasUuid });
    if (error) {
      alert(error.msg);
      history.goBack();
      return;
    }
    // console.log(data);
    if (!data.loginSuccess) {
      alert(data.msg);
      history.replace('/');
    } else if (data.isUser) {
      // 이미 가입된 유저.
      localStorage.setItem('access-token', data.access);
      localStorage.setItem('refresh-token', data.refresh);
      history.replace('/');
    } else if (window.confirm(data.msg)) {
      localStorage.setItem('uuid', data.kopasUuid);
      history.replace('/account/signup/');
    }
    // history.goBack();
  };
  useEffect(() => {
    checkUser();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <div>
        자동 로그인 중입니다!
        <br />
        잠시만 기다려주세요.
      </div>
    </>
  );
};

export default OauthKopas;
