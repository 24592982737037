/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import { CourseCard } from '..';
import * as S from '../style';

const PSearchSection = ({ regStatus, info, isPresent }) => {
  const { courCount, data } = info;
  const [isMore, setIsMore] = useState(false);
  const moreBtn = () => {
    setIsMore(!isMore);
  };
  const MoreBtn = isMore ? (
    <AiFillMinusCircle style={{ fontSize: '40px' }} />
  ) : (
    <AiFillPlusCircle style={{ fontSize: '40px' }} />
  );

  return (
    <>
      <S.SsContainer>
        {data &&
          data.map((course, index) => {
            if (index <= 5) {
              return (
                <CourseCard
                  key={index}
                  regStatus={regStatus}
                  course={course}
                  isPresent={isPresent}
                />
              );
            }

            if (index > 5 && isMore) {
              return (
                <CourseCard
                  key={index}
                  regStatus={regStatus}
                  course={course}
                  isPresent={isPresent}
                />
              );
            }
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return null;
          })}
      </S.SsContainer>
      <S.IsMoreBtn onClick={moreBtn}>
        {courCount > 6 ? MoreBtn : ''}
      </S.IsMoreBtn>
    </>
  );
};
export default PSearchSection;
