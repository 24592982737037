import React, { useState, useEffect } from 'react';
import { Footer } from '../../components/Common';
import { MainContents, MainHeader } from '../../components/Main';
import { NotiPopup, Popup, EventPopup } from '../../components/Popup';
import { IS_POPUP_OPEN } from '../../constants/DEFAULT_VALUE';

const Main = () => {
  const [isOpenP, setIsOpenP] = useState(false);
  const closePopup = () => {
    setIsOpenP(false);
  };

  useEffect(() => {
    // 첫 방문 시 로컬 스토리지 비우기
    if (!localStorage.getItem('fstView')) {
      localStorage.clear();
      localStorage.setItem('fstView', true);
    }
    if (
      IS_POPUP_OPEN &&
      // localStorage.getItem('access-token') && // 로그인한 유저만 보여줄 것인지
      // eslint-disable-next-line eqeqeq
      localStorage.getItem('popupViewed1') != Math.floor(new Date().getDate())
      // localStorage.getItem('popup-viewed') == null
    ) {
      setIsOpenP(true);
    }
  }, []);

  return (
    <>
      <MainHeader />
      <MainContents />
      <Footer />
      {isOpenP && <EventPopup isOpenP={isOpenP} closePopup={closePopup} />}
    </>
  );
};

export default Main;
