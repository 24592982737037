import styled from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';

export const TextContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 20px 20px 0px 20px;
  div:nth-child(1) {
    margin-top: 5px;
    margin-bottom: 25px;
    padding-left: 0;
  }
`;

export const LineBreak = styled.div`
  margin-top: 10px;
  width: 100%;
  padding: 0 12px;
  hr {
    border: solid 1px black;
  }
`;

export const LoginHeading = styled.div`
  width: 100%;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  font-size: 32px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.32px;
  text-align: left;
  color: #000000;
  span {
    padding-left: 12px;
  }
  & > div:first-child > p {
    color: #000000;
  }
  & > div:nth-child(2) > p {
    color: #000000;
  }
  & > div:nth-child(2) > p > img {
    width: 38px;
    vertical-align: text-bottom;
  }
  & > div > p > span {
    color: #000000;
    margin-right: 12px;
    height: min-content;
  }
`;

export const LoginText = styled.div`
  width: -webkit-fill-available;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  text-align: left;
  padding-left: 12px;
`;
export const Signupform = styled.form`
  animation: fadeIn 0.5s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 3;
      transform: none;
    }
  }
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  position: relative;
  &:valid button {
    background: black;
    cursor: pointer;
  }
`;
export const Signuplabel = styled.label`
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #767676;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-top: 5px;
`;

export const Signupnickname = styled.input`
  border: none;
  border-bottom: ${(props) =>
    props.value ? '1px solid #747474' : '1px solid #cfcfcf'};
  padding: 6px 0 6px 5px;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.44px;
  text-align: left;
  outline: none;
  color: #000000;
  text-decoration: none;
  &:focus {
    border-bottom: 1px solid #8b0728;
  }
  &::placeholder {
    color: #e9e9e9;
  }
`;

export const SignupfirstWrapper = styled.div`
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SignupGroupWrapper = styled.div`
  overflow: hidden;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SignupCheckWrapper = styled.div`
  overflow: hidden;
  min-width: 62px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SignupDiv = styled.div`
  width: 20px;
`;

export const SignupDownIcon = styled(FiChevronDown)`
  font-size: 20px;
  color: #484848;
  position: absolute;
  right: 1px;
  top: 63px;
  z-index: 0;
  margin-top: 4px;
`;

export const SignupSmlSelect = styled.select`
  background-color: rgba(255, 255, 255, 0);
  color: ${(props) => (props.value ? '#484848' : '#cfcfcf')};
  border: none;
  border-bottom: ${(props) =>
    props.value ? '1px solid #747474' : '1px solid #cfcfcf'};
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.44px;
  -webkit-appearance: none;
  border-radius: initial;
  text-align: left;
  text-decoration: none;
  padding: 6px 0 6px 5px;
  outline: none;
  z-index: 1;
  &:focus {
    border-bottom: 1px solid #8b0728;
  }
  &:hover {
    cursor: pointer;
  }
`;
// Chrome을 v/81로 업그레이드한 후 모든 확인란과 라디오 버튼이 파란색으로 바뀌었습니다.
export const SignupCheckbox = styled.input`
  margin-top: 11px;
  margin-bottom: 6px;
  width: 20px;
  height: 20px;

  align-self: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 1.5px solid gray;
  box-shadow: none;
  font-size: 16px;
  text-align: center;
  line-height: 1em;

  &:checked {
    &::after {
      content: '✓';
      font-size: 1.45rem;
    }
  }
`;

export const SignupBigSelect = styled.select`
  color: ${(props) => (props.value ? '#484848' : '#cfcfcf')};
  border: none;
  border-bottom: ${(props) =>
    props.value ? '1px solid #747474' : '1px solid #cfcfcf'};
  background-color: rgba(255, 255, 255, 0);
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.44px;
  text-align: left;
  text-decoration: none;
  -webkit-appearance: none;
  border-radius: initial;
  padding: 6px 0 6px 5px;
  outline: none;
  z-index: 1;
  &:focus {
    border-bottom: 1px solid #8b0728;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const SignupBtn = styled.button`
  font-size: 1rem;
  font-weight: 700;
  background: #cfcfcf;
  height: 60px;
  color: white;
  border: none;
  border-radius: 10px;
  margin: 50px 0 40px 0;
`;
