import React from 'react';
import { RiHome5Line, RiBookmarkLine, RiUser3Line } from 'react-icons/ri';
import { Link, withRouter } from 'react-router-dom';
import { REG } from '../../constants/DEFAULT_VALUE';
import * as S from './style';

const Footer = ({ location: { pathname } }) => {
  return (
    <S.FooterWrapper>
      <Link to="/">
        <S.FooterIcon active={pathname === '/'}>
          <RiHome5Line />
          <p>홈</p>
        </S.FooterIcon>
      </Link>
      <Link to={`/favorites/${REG}/`}>
        <S.FooterIcon
          active={
            pathname === '/favorites/prereg/' ||
            pathname === '/favorites/register/'
          }
        >
          <RiBookmarkLine />
          <p>즐겨찾기</p>
        </S.FooterIcon>
      </Link>
      <Link to="/account/mypage/">
        <S.FooterIcon active={pathname === '/account/mypage/'}>
          <RiUser3Line />
          <p>마이페이지</p>
        </S.FooterIcon>
      </Link>
    </S.FooterWrapper>
  );
};

export default withRouter(Footer);
