import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PMainContents from './PMainContents';
import * as S from './style';
import { getMainInfoApi } from '../../../utils/feed';
import { hasToken } from '../../../utils/auth';
import Loading from '../../Common/Loading.jsx';

const MainContents = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const category = {
    notice: 'notice',
    story: 'story',
  };

  const checkLogin = async () => {
    const data = await hasToken();
    setIsLogin(data);
  };

  const [noticeList, setNoticeList] = useState([]);
  const noticeData = async (category) => {
    const [data, error] = await getMainInfoApi(category);
    if (data) {
      const newList = data.map((el) => {
        const link = `/feed/notice/${el.id}`;
        return (
          <Link to={link} key={el.id}>
            <S.BoardContents key={el.id}>
              <S.CreateAt>{el.createdDay}</S.CreateAt>
              <S.NoticeBoardTitle>{el.title}</S.NoticeBoardTitle>
            </S.BoardContents>
          </Link>
        );
      });
      setNoticeList(newList);
    } else {
      // eslint-disable-next-line no-console
      console.dir(error);
    }
  };
  const [storyList, setStoryList] = useState([]);
  const storyData = async (category) => {
    const [data, error] = await getMainInfoApi(category);
    if (data) {
      setIsLoading(false);
      const newList = data.map((el) => {
        const link = `/feed/story/${el.id}`;
        return (
          <Link to={link} key={el.id}>
            <S.Contents>
              <img src={el.firstImgUrl} alt="error" width="100%" />
              <S.StoryBoardTitle>{el.title}</S.StoryBoardTitle>
              <S.CreateAt>{el.createdDay}</S.CreateAt>
            </S.Contents>
          </Link>
        );
      });
      setStoryList(newList);
    } else {
      // eslint-disable-next-line no-console
      console.dir(error);
    }
  };

  useEffect(() => {
    checkLogin();
    noticeData(category.notice);
    storyData(category.story);
  }, []);

  if (isLoading && !isLogin) {
    return <Loading />;
  }

  return (
    <PMainContents
      isLogin={isLogin}
      noticeList={noticeList}
      storyList={storyList}
    />
  );
};

export default MainContents;
